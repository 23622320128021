// Utils
import { get, post, put, upload, destroy } from 'app/shared/utils/fetchr';

// Models
import {
  CreateAlertPayload,
  GetAlertsPayload,
  ChangeAlertStatusPayload,
  DeleteDocumentPayload,
  AssociateAlertsWithCasesApi,
  RetrieveUnassignedAlertsPayload,
  RetrieveAlertHistogramPayload,
  AddDocumentsPayload,
} from 'app/modules/alerts/models';
import { TriggerActionPayload } from 'app/modules/webhooks/models';
import {
  SingleFileExportPayload,
  BulkFileExportPayload,
} from 'app/modules/fileExportsOld/models';

// Actions
import {
  editAlert,
  retrieveAlertActionEvents as retrieveAlertActionEventsAction,
} from 'app/modules/alerts/actions';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';
import { removeNullKeys } from 'app/shared/utils/apiUtils';
import { QueueType } from 'app/modules/queues/models';
import {
  UploadFilesPayload,
  RemoveDocumentFromAlertResponse,
} from 'app/modules/uploads/models';
import { RuleValidationAlertActionEventsResponse } from 'app/modules/rules/models';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';

export const updateAlert = (
  payload: ReturnType<typeof editAlert>['payload'],
) => {
  const { id, ...details } = payload;
  return put(routes.patronus.alertsEditId.replace(':id', String(id)), details);
};

export const associateAlertsWithCases = (
  payload: AssociateAlertsWithCasesApi,
) => {
  return put(routes.patronus.alertsAssociatedCases, payload);
};

export const getAlerts = (payload: GetAlertsPayload) => {
  return post(routes.patronus.alertsList, payload);
};

export const getMyAlertsStats = () => {
  return get(routes.patronus.queuesMyAlertsStats);
};

export const createAlert = (payload: CreateAlertPayload) => {
  return post(routes.patronus.alertsCreate, payload);
};

export const getAlert = (alertId: number | string) => {
  const path = routes.patronus.alertsAlertId.replace(
    ':alertId',
    String(alertId),
  );
  return get(path);
};

export const getAlertTransactions = (id: string, payload) => {
  const path = routes.patronus.alertsIdTransactions.replace(':id', id);
  return post(path, payload);
};

export const retrieveAlertAuditTrail = (alertId: number | string) => {
  const path = routes.patronus.alertsIdAuditTrail.replace(
    ':id',
    String(alertId),
  );
  return get(path);
};

export const retrieveAlertEntities = (id: string, payload) => {
  const path = routes.patronus.alertsIdEntities.replace(':id', id);
  return post(path, payload);
};

export const retrieveAlertInstruments = (id: string, payload) => {
  const path = routes.patronus.alertsIdInstruments.replace(':id', id);
  return post<{ instruments: ShortTxnInstrumentResponse[] }>(path, payload);
};

export const alertComponentStatusChange = (
  payload: ChangeAlertStatusPayload,
) => {
  const { alertId, ...details } = payload;
  const path = routes.patronus.alertsIdComponentResolution.replace(
    ':id',
    `${alertId}`,
  );
  return post(path, details);
};

export const reassignAlerts = (payload) => {
  return post(routes.patronus.alertsReassign, payload);
};

export const requeueAlerts = (payload) => {
  return post(routes.patronus.requeueAlerts, payload);
};

export const addDocuments = (payload: AddDocumentsPayload) => {
  const { alertId, documents } = payload;
  const docs = documents.map((doc) => removeNullKeys(doc));

  return post(pathJoin('/alerts', alertId, '/attachments', 'add'), {
    docs,
  });
};

export const uploadFile = (payload: UploadFilesPayload) => {
  const formData = new FormData();

  payload.files?.forEach((S3File, index) => {
    formData.append(`file${index}`, S3File);
  });

  return upload(
    routes.patronus.alertsIdAddFiles.replace(':id', payload.id),
    formData,
  );
};

export const deleteDocument = (payload: DeleteDocumentPayload) => {
  const { alertId, docId } = payload;
  const alertPath = routes.patronus.alertsIdAttachmentId.replace(
    ':id',
    alertId.toString(),
  );
  const fullPath = alertPath.replace(':attachmentId', docId.toString());
  return destroy<RemoveDocumentFromAlertResponse>(fullPath);
};

export const triggerAction = (payload: TriggerActionPayload) => {
  const { resourceId, actionId, ...body } = payload;
  return post(
    routes.patronus.alertsTriggerAction.replace(':id', String(resourceId)),
    { action_trigger_id: actionId, ...body },
  );
};

export const sendSingleAlertXlsxRequest = (
  payload: SingleFileExportPayload,
) => {
  return post(routes.patronus.alertsSingleEmailXlsx, payload);
};

export const sendBulkAlertsXlsxRequest = (payload: BulkFileExportPayload) => {
  return post(routes.patronus.alertsBulkXlsx, payload);
};

export const retrieveAlertQueues = (payload) =>
  post(
    routes.patronus.queuesList,
    { ...payload, type: QueueType.ALERT_QUEUE },
    [{ key: 'type', value: QueueType.ALERT_QUEUE }],
  );

export const retrieveAllAlertQueues = () =>
  get(routes.patronus.queues, [{ key: 'type', value: QueueType.ALERT_QUEUE }]);

export const retrieveUnassignedAlerts = (
  payload: RetrieveUnassignedAlertsPayload,
) => post(routes.patronus.unassignedAlerts, payload);

export const getAlertHistogram = (payload: RetrieveAlertHistogramPayload) => {
  return post(routes.patronus.alertsHistogram, payload);
};

export const retrieveAlertActionEvents = ({
  alert_id: id,
  ...payload
}: ReturnType<typeof retrieveAlertActionEventsAction>['payload']) => {
  const path = routes.patronus.alertsIdActionEvents.replace(':id', `${id}`);
  return post<RuleValidationAlertActionEventsResponse>(path, payload);
};
