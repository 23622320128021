// Models
import {
  SimpleModelStep,
  StepPayload,
  ModelValidationPayload,
  DetectionModelMetadata,
  DetectionModelValidation,
  ScenarioCategory,
  DetectionModel,
  AdvancedModelStep,
  ModelsState,
  ScenarioCategoryConfig,
} from 'app/modules/detectionModels/models';
import {
  CompositeQueryTreeNode,
  ConstantValue,
  IRSchema,
  VariableValue,
  MatchlistFieldValue,
  DurationValue,
  DmObjectToFlag,
} from 'app/modules/rulesAdvanced/types/RulesRepresentation';

// Helpers
import {
  validateSimpleModelMetadata,
  validateSimpleModelScenario,
  validateSimpleModelScenarioContent,
  validateValidationPicker,
} from 'app/modules/detectionModels/stepValidations';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

import { consoleError } from 'app/shared/utils/console';
import {
  DEFAULT_DATA_MIGRATION_LAG,
  initialCurrentRule,
} from 'app/modules/rules/constants';
import { format, sub, subMinutes } from 'date-fns';
import {
  U21ButtonGroupButtonProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { SymbolFieldValue } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { CytoscapeOptions } from 'cytoscape';
import {
  BASE_EDGE_STYLES,
  BASE_LABEL_STYLES,
  BASE_CYTOSCAPE_OPTIONS,
  BASE_NODE_STYLES,
} from 'app/shared/components/Graphs/constants';
import { CONNECTION_TYPE_TO_ICON } from 'app/modules/detectionModels/components/scenarioWidgets/constants';
import { User } from 'app/shared/components/Graphs/svgs';
import { RealTimeMonitoringCount } from 'app/modules/rules/types/responses';

const today = new Date();
const endTime = format(
  subMinutes(today, DEFAULT_DATA_MIGRATION_LAG),
  'dd MMM yyyy HH:00',
);
const startTime = format(sub(today, { days: 30 }), 'dd MMM yyyy HH:00');

export const EMPTY_VALIDATION_PAYLOAD: ModelValidationPayload = {
  start_time: startTime,
  end_time: endTime,
  execution_frequency: '',
};

export const EMPTY_MODEL_METADATA: DetectionModelMetadata = {
  title: '',
  description: '',
  customer_support_involved: false,
  tags: [],
  queue: -1,
  runs_on_org_id: null,
  visible_to_child_org: false,
};

export const EMPTY_MODEL_VALIDATION: DetectionModelValidation = {
  id: -1,
  scenario: '',
  scenario_type: '',
  tags: [],
  queue: -1,
  runs_on_org_id: null,
  title: '',
  customer_support_involved: false,
  description: '',
  execution_frequency: '',
  created_at: '',
  completed_at: '',
  status: '',
  deploy_end_date: '',
  deploy_start_date: '',
  created_by_readable: '',
  validation_id: null,
  exec_range_start: '',
  exec_range_end: '',
  override_qbc: null,
  progress: {
    cur_processing_window: -1,
    num_windows: -1,
  },
  validation_result: {
    hit_key_field: '',
    hit_value_field: '',
    metrics: [],
    rule_validation_histograms: {
      in: [],
      out: [],
    },
    rule_validation_id: -1,
    rule_validation_results: [],
    rule_validation_windows: [],
    status: '',
    validation_histogram: null,
  },
  content: {
    scenario: {
      filters: {},
      parameters: {},
      name: '',
    },
  },
  template: null,
  visible_to_child_org: false,
  friendly_errors: null,
  results: null,
};

export const SIMPLE_MODEL_STEPS: { [key in SimpleModelStep]: StepPayload } = {
  SCENARIO_PICKER: {
    label: 'Scenario Picker',
    canContinue: validateSimpleModelScenario,
    nextStep: 'SCENARIO_BUILDER',
  },
  SCENARIO_BUILDER: {
    label: 'Model Content',
    canContinue: validateSimpleModelScenarioContent,
    nextStep: 'METADATA_PICKER',
  },
  METADATA_PICKER: {
    label: 'Description',
    canContinue: validateSimpleModelMetadata,
    nextStep: 'VALIDATION_PICKER',
  },
  VALIDATION_PICKER: {
    label: 'Time Window',
    canContinue: validateValidationPicker,
    nextStep: null,
  },
};

export const SIMPLE_MODEL_STEPS_LIST = ((navigationConfig: {
  [key in SimpleModelStep]: StepPayload;
}): {
  id: SimpleModelStep;
  label: string;
}[] => {
  const steps: SimpleModelStep[] = [];

  const allSteps = Object.keys(navigationConfig) as SimpleModelStep[];
  const nextSteps = Object.values(navigationConfig).map(
    (stepConfig) => stepConfig.nextStep,
  );

  const initialStep = allSteps.filter((step) => !nextSteps.includes(step));
  if (initialStep.length !== 1) {
    // There's an error in the config, make sure we don't have recursive steps
    consoleError('Unable to get initial step from config');
    return [];
  }

  let currentStep: SimpleModelStep | null = initialStep[0];
  let { nextStep } = navigationConfig[currentStep];
  while (currentStep !== null) {
    steps.push(currentStep);
    currentStep = nextStep;
    nextStep = navigationConfig[currentStep || '']?.nextStep;
  }

  return steps.map((id) => ({
    id,
    label: navigationConfig[id].label,
  }));
})(SIMPLE_MODEL_STEPS);

export const FLAGGED_OBJECT_PARAMETER = 'alert_group_by_policy';

// Validation Constants
export const VALIDATION_PROGRESS_MAX_BLOCKS = 10;
export const VALIDATION_PROGRESS_BLOCKS_TOTAL_WIDTH =
  VALIDATION_PROGRESS_MAX_BLOCKS * 29 +
  Math.max(VALIDATION_PROGRESS_MAX_BLOCKS - 2, 0) * 6;

export const DEFAULT_SCENARIO_CATEGORY: ScenarioCategory = 'OTHER';

export const DEFAULT_CATEGORY_ARRAY = [
  { category: 'ANOMALY', id: 1 },
  { category: 'LIST', id: 2 },
  { category: 'CARDINALITY', id: 3 },
  { category: 'STATISTICS', id: 4 },
  { category: 'STRUCTURING', id: 5 },
  { category: 'CHECK_FRAUD', id: 6 },
  { category: 'OTHER', id: 7 },
];

export const SCENARIO_CATEGORY_CONFIG: ScenarioCategoryConfig = {
  STRUCTURING: {
    label: 'Structuring/Smurfing',
  },
  ANOMALY: {
    label: 'Anomaly Detection',
  },
  CARDINALITY: {
    label: 'Cardinality',
  },
  STATISTICS: {
    label: 'Statistics',
  },
  BLACKLIST: {
    // NOTE: for legacy support
    label: 'Lists',
  },
  LIST: {
    label: 'Lists',
  },
  CHECK_FRAUD: {
    label: 'Check Fraud',
    showNewLabel: true,
  },
  ACH_FRAUD: {
    label: 'ACH Fraud',
    showNewLabel: true,
  },
  OTHER: {
    label: 'Other',
  },
};

export const EMPTY_DETECTION_MODEL: DetectionModel = { ...initialCurrentRule };

export const ADVANCED_MODEL_STEPS: {
  id: AdvancedModelStep;
  label: string;
}[] = [
  {
    id: 'TEMPLATE_PICKER',
    label: 'Select Template',
  },
  {
    id: 'ADVANCED_BUILDER',
    label: 'Define Rule',
  },
  {
    id: 'METADATA_PICKER',
    label: 'Model Detail',
  },
  {
    id: 'VALIDATION_PICKER',
    label: 'Time Window',
  },
];

export const EMPTY_RULE_CONDITION_GROUP: CompositeQueryTreeNode = {
  type: 'rule_condition_composite',
  operands: [],
  operator: 'AND',
};

export const EMPTY_RULE_CONDITION_COMPOSITE: CompositeQueryTreeNode = {
  type: 'rule_condition_composite',
  operands: [],
  operator: 'EQ',
};

export const EMPTY_IR_RULE: IRSchema = {
  facts: [],
  rule_condition: {
    type: 'rule_condition_composite',
    operands: [],
    operator: 'AND',
  },
  object: 'ENTITY',
};

export const POPULARITY_THRESHOLD = 70;

export const EMPTY_VALIDATE_MODEL_PAYLOAD = {
  contents: {
    scenario: {
      scenario_name: 'scenario_name',
      parameters: {
        $time_window: 'time_window',
        $window_stride: 'window_stride',
        alert_group_by_policy: 'alert_group_by_policy',
        count_field: 'count_field',
        group_type: 'group_type',
        txn_events: 'txn_events',
        param: {},
      },
      filters: {},
    },
    execution_frequency: 'execution_frequency',

    // TODO: to be removed. see modules/detectionModels/models.ts line 415
    query_tree: {},
    inclusion_tags: [],
    inclusion_tag_names: [],
    exclusion_tags: [],
    exclusion_tag_names: [],
    specification: undefined,
  },
  exec_range_start: 'exec_range_start',
  exec_range_end: 'exec_range_end',
  tags: [],
  alert_queue_id: null,
  runs_on_org_id: null,
  metadata: {
    title: 'title',
    description: 'description',
    customer_support_involved: false,
    parentRuleId: undefined,
    exec_start_date_time: 'execStartDatetime',
    exec_end_date_time: 'execEndDatetime',
  },
  template_id: undefined,
  visible_to_child_org: undefined,
};

export const INITIAL_EDITING_SIMPLE_DETECTION_MODEL = {
  metadata: { ...EMPTY_MODEL_METADATA },
  validationPayload: { ...EMPTY_VALIDATION_PAYLOAD },
  objectToFlag: null,
  scenario: null,
};

export const initialState: Readonly<ModelsState> = {
  detectionModelsPerformance: {
    series: [],
    metrics: [],
  },
  validateModelPayload: EMPTY_VALIDATE_MODEL_PAYLOAD,
  editingSimpleDetectionModel: INITIAL_EDITING_SIMPLE_DETECTION_MODEL,
  editingAdvancedDetectionModel: {
    template: null,
    irSchema: { ...EMPTY_IR_RULE },
    metadata: { ...EMPTY_MODEL_METADATA },
    validationPayload: {
      ...EMPTY_VALIDATION_PAYLOAD,
    },
  },
  scenarioConfigs: {},
  detectionModelValidation: { ...EMPTY_MODEL_VALIDATION },
  detectionModelValidationMetrics: {
    id: -1,
    metrics: [],
  },
  validationAlerts: {
    alerts: [],
    count: 0,
  },
  detectionModelTemplates: {
    templates: [],
    count: 0,
  },
};

// to ensure initialState is readonly
deepFreeze(initialState);

export const DM_STATUS_TO_COLOR = {
  COMPLETE: 'success',
  FAILED: 'error',
  QUERY_TIMEOUT: 'error',
  ACTIVE: 'success',
  INACTIVE: 'error',
  PENDING: 'info',
};

export const OBJECT_TO_FLAG_TO_MODEL: Record<DmObjectToFlag, string> = {
  ACTION_EVENT: 'action_event',
  DEVICE: 'device',
  ENTITY: 'entity',
  INSTRUMENT: 'instrument',
  TXN_EVENT: 'txn_event',
};

export enum NodeTag {
  TAG = 'TAG',
  MATCHLIST = 'MATCHLIST',
}

export enum DmSenderReceiverValues {
  SENDER = 'SENDER',
  RECEIVER = 'RECEIVER',
  SENDER_OR_RECEIVER = 'SENDER_OR_RECEIVER',
}

export enum DropdownBuilderFormFields {
  LEFT_OPERAND = 'leftOperand',
  OPERATOR = 'operator',
  CONDITION = 'condition',
  CONDITIONS = 'conditions',
  EVENT_SUB_TYPES = 'eventSubTypes',
  FACTS = 'facts',
  OBJECT_TO_FLAG = 'objectToFlag',
}

export const DROPDOWN_BUILDER_REMOVABLE_COMPONENT_TYPES: string[] = [
  'rule_condition_composite',
  'VARIABLE',
];
export enum DropdownBuilderUiTypes {
  CONSTANT = 'CONSTANT',
  FIELD = 'FIELD',
  PERCENTAGE = 'MUL',
  AVERAGE = 'AVERAGE',
  MATCHLIST = 'MATCHLIST',
  EMPTY = 'EMPTY',
}

export const DROPDOWN_BUILDER_UI_TYPES_MAP: Record<
  DropdownBuilderUiTypes,
  U21SelectOptionProps
> = {
  [DropdownBuilderUiTypes.CONSTANT]: {
    text: 'input a value',
    value: DropdownBuilderUiTypes.CONSTANT,
  },
  [DropdownBuilderUiTypes.FIELD]: {
    text: 'select a field',
    value: DropdownBuilderUiTypes.FIELD,
  },
  [DropdownBuilderUiTypes.PERCENTAGE]: {
    text: 'find a percentage',
    value: DropdownBuilderUiTypes.PERCENTAGE,
  },
  [DropdownBuilderUiTypes.AVERAGE]: {
    text: 'find an average',
    value: DropdownBuilderUiTypes.AVERAGE,
  },
  [DropdownBuilderUiTypes.MATCHLIST]: {
    text: 'select a matchlist',
    value: DropdownBuilderUiTypes.MATCHLIST,
  },
  [DropdownBuilderUiTypes.EMPTY]: {
    text: 'check if empty or not',
    value: DropdownBuilderUiTypes.EMPTY,
  },
};

const NUMBER_DROPDOWN_BUILDER_UI_TYPE_OPTIONS: U21SelectOptionProps[] = [
  DROPDOWN_BUILDER_UI_TYPES_MAP.CONSTANT,
  DROPDOWN_BUILDER_UI_TYPES_MAP.FIELD,
  DROPDOWN_BUILDER_UI_TYPES_MAP.MUL,
  DROPDOWN_BUILDER_UI_TYPES_MAP.AVERAGE,
  DROPDOWN_BUILDER_UI_TYPES_MAP.EMPTY,
];

const STRING_OR_DATE_UI_TYPE_OPTIONS: U21SelectOptionProps[] = [
  DROPDOWN_BUILDER_UI_TYPES_MAP.CONSTANT,
  DROPDOWN_BUILDER_UI_TYPES_MAP.FIELD,
  DROPDOWN_BUILDER_UI_TYPES_MAP.EMPTY,
];

const PERCENTAGE_UI_TYPE_OPTIONS: U21SelectOptionProps[] = [
  DROPDOWN_BUILDER_UI_TYPES_MAP.FIELD,
  DROPDOWN_BUILDER_UI_TYPES_MAP.AVERAGE,
];

export const DROPDOWN_BUILDER_NEXT_STEP_OPTIONS: Record<
  string,
  U21SelectOptionProps[]
> = {
  date: STRING_OR_DATE_UI_TYPE_OPTIONS,
  datetime: STRING_OR_DATE_UI_TYPE_OPTIONS,
  string: STRING_OR_DATE_UI_TYPE_OPTIONS,
  enum: STRING_OR_DATE_UI_TYPE_OPTIONS,
  list: STRING_OR_DATE_UI_TYPE_OPTIONS,
  boolean: STRING_OR_DATE_UI_TYPE_OPTIONS,
  [DropdownBuilderUiTypes.PERCENTAGE]: PERCENTAGE_UI_TYPE_OPTIONS,
  number: NUMBER_DROPDOWN_BUILDER_UI_TYPE_OPTIONS,
};

export enum ConditionalOperators {
  GT = 'GT',
  LT = 'LT',
  LE = 'LE',
  GE = 'GE',
  EQ = 'EQ',
  ANY_EQ = 'ANY_EQ',
  NEQ = 'NEQ',
  NONE_EQ = 'NONE_EQ',
  IN = 'IN',
  ANY_IN = 'ANY_IN',
  NOT_IN = 'NOT_IN',
  NONE_IN = 'NONE_IN',
  LIKE = 'LIKE',
  ANY_LIKE = 'ANY_LIKE',
  NOT_LIKE = 'NOT_LIKE',
  NONE_LIKE = 'NONE_LIKE',
  BETWEEN = 'BETWEEN',
  IS = 'IS',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  ANY_IS_EMPTY = 'ANY_IS_EMPTY',
  NONE_IS_EMPTY = 'NONE_IS_EMPTY',
}

export const CONDITIONAL_OPERATORS_MAP: Record<
  ConditionalOperators,
  U21SelectOptionProps
> = {
  [ConditionalOperators.GT]: { value: ConditionalOperators.GT, text: '>' },
  [ConditionalOperators.LT]: { value: ConditionalOperators.LT, text: '<' },
  [ConditionalOperators.LE]: {
    value: ConditionalOperators.LE,
    text: '<=',
  },
  [ConditionalOperators.GE]: {
    value: ConditionalOperators.GE,
    text: '>=',
  },
  [ConditionalOperators.EQ]: {
    value: ConditionalOperators.EQ,
    text: 'is equal to',
  },
  [ConditionalOperators.ANY_EQ]: {
    value: ConditionalOperators.EQ,
    text: 'is equal to',
  },
  [ConditionalOperators.NEQ]: {
    value: ConditionalOperators.NEQ,
    text: 'is not equal to',
  },
  [ConditionalOperators.NONE_EQ]: {
    value: ConditionalOperators.NEQ,
    text: 'is not equal to',
  },
  [ConditionalOperators.IN]: { value: ConditionalOperators.IN, text: 'is in' },
  [ConditionalOperators.ANY_IN]: {
    value: ConditionalOperators.IN,
    text: 'is in',
  },
  [ConditionalOperators.NOT_IN]: {
    value: ConditionalOperators.NOT_IN,
    text: 'is not in',
  },
  [ConditionalOperators.NONE_IN]: {
    value: ConditionalOperators.NOT_IN,
    text: 'is not in',
  },
  [ConditionalOperators.LIKE]: {
    value: ConditionalOperators.LIKE,
    text: 'is like',
  },
  [ConditionalOperators.ANY_LIKE]: {
    value: ConditionalOperators.LIKE,
    text: 'is like',
  },
  [ConditionalOperators.NOT_LIKE]: {
    value: ConditionalOperators.NOT_LIKE,
    text: 'is not like',
  },
  [ConditionalOperators.NONE_LIKE]: {
    value: ConditionalOperators.NOT_LIKE,
    text: 'is not like',
  },
  [ConditionalOperators.BETWEEN]: {
    value: ConditionalOperators.BETWEEN,
    text: 'is between',
  },
  [ConditionalOperators.IS]: {
    value: ConditionalOperators.IS,
    text: 'is',
  },
  [ConditionalOperators.IS_EMPTY]: {
    value: ConditionalOperators.IS_EMPTY,
    text: 'is empty',
    tooltip: "Is empty will check if this field is 'NULL' or empty.",
  },
  [ConditionalOperators.IS_NOT_EMPTY]: {
    value: ConditionalOperators.IS_NOT_EMPTY,
    text: 'is not empty',
    tooltip:
      "Is not empty will check if this field is not 'NULL' or empty. Any value that is not 'NULL' or empty will flag.",
  },
  [ConditionalOperators.ANY_IS_EMPTY]: {
    value: ConditionalOperators.ANY_IS_EMPTY,
    text: 'is empty',
    tooltip: "Is empty will check if this field is 'NULL' or empty.",
  },
  [ConditionalOperators.NONE_IS_EMPTY]: {
    value: ConditionalOperators.NONE_IS_EMPTY,
    text: 'is not empty',
    tooltip:
      "Is not empty will check if this field is not 'NULL' or empty. Any value that is not 'NULL' or empty will flag.",
  },
};

export const IS_OPERAND_OPTIONS: U21SelectOptionProps[] = [
  {
    value: 'NULL',
    text: 'NULL',
  },
];

const DATE_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  {
    value: ConditionalOperators.GT,
    text: 'is after',
  },
  {
    value: ConditionalOperators.GE,
    text: 'is on or after',
  },
  {
    value: ConditionalOperators.LT,
    text: 'is before',
  },
  {
    value: ConditionalOperators.LE,
    text: 'is on or before',
  },
  {
    value: ConditionalOperators.EQ,
    text: 'is on',
  },
  {
    value: ConditionalOperators.NEQ,
    text: 'is not on',
  },
  CONDITIONAL_OPERATORS_MAP.BETWEEN,
  CONDITIONAL_OPERATORS_MAP.IS,
];

const NUMBER_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  {
    value: ConditionalOperators.EQ,
    text: '=',
  },
  {
    value: ConditionalOperators.NEQ,
    text: '!=',
  },
  CONDITIONAL_OPERATORS_MAP.GT,
  CONDITIONAL_OPERATORS_MAP.LT,
  CONDITIONAL_OPERATORS_MAP.GE,
  CONDITIONAL_OPERATORS_MAP.LE,
  CONDITIONAL_OPERATORS_MAP.BETWEEN,
  CONDITIONAL_OPERATORS_MAP.IS,
];

const BOOLEAN_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  {
    value: ConditionalOperators.EQ,
    text: '=',
  },
  CONDITIONAL_OPERATORS_MAP.IS,
];

const EMPTY_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  CONDITIONAL_OPERATORS_MAP.IS_EMPTY,
  CONDITIONAL_OPERATORS_MAP.IS_NOT_EMPTY,
];

const STRING_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  CONDITIONAL_OPERATORS_MAP.EQ,
  CONDITIONAL_OPERATORS_MAP.NEQ,
  CONDITIONAL_OPERATORS_MAP.LIKE,
  CONDITIONAL_OPERATORS_MAP.NOT_LIKE,
  CONDITIONAL_OPERATORS_MAP.IS,
];

const LIST_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  CONDITIONAL_OPERATORS_MAP.IN,
  CONDITIONAL_OPERATORS_MAP.NOT_IN,
  CONDITIONAL_OPERATORS_MAP.IS,
];

export const MATCHLIST_OPERATOR_OPTIONS: U21SelectOptionProps[] = [
  CONDITIONAL_OPERATORS_MAP.IN,
  CONDITIONAL_OPERATORS_MAP.NOT_IN,
];

export const IN_OPERATORS = new Set<string>([
  ConditionalOperators.IN,
  ConditionalOperators.ANY_IN,
  ConditionalOperators.NOT_IN,
  ConditionalOperators.NONE_IN,
]);

export const OPERAND_TYPE_OPERATOR_OPTIONS_MAP: Record<
  string,
  U21SelectOptionProps[]
> = {
  boolean: BOOLEAN_OPERATOR_OPTIONS,
  date: DATE_OPERATOR_OPTIONS,
  datetime: DATE_OPERATOR_OPTIONS,
  number: NUMBER_OPERATOR_OPTIONS,
  string: STRING_OPERATOR_OPTIONS.concat(LIST_OPERATOR_OPTIONS),
  text: STRING_OPERATOR_OPTIONS.concat(LIST_OPERATOR_OPTIONS),
  list: LIST_OPERATOR_OPTIONS,
  empty: EMPTY_OPERATOR_OPTIONS,
};

export const SEQUENCE_RULES: string[] = [
  'sell_at_loss_sequence_embedded_filters',
  'relative_transaction_amount_sequence_embedded_filters',
];
export const ACH_RISK_SCORE_ENABLED_SCENARIO_RULES: string[] = [
  'simple_filters',
];
export const SCENARIO_CONSORTIUM_NEW_ACCOUNT_NAME =
  'consortium_new_account_monitoring';
export const SCENARIO_CONSORTIUM_ONGOING_ACCOUNT_NAME =
  'consortium_ongoing_account_monitoring';
export const CONSORTIUM_RULES: string[] = [
  SCENARIO_CONSORTIUM_NEW_ACCOUNT_NAME,
  SCENARIO_CONSORTIUM_ONGOING_ACCOUNT_NAME,
];

export const DORMANT_ACTIVITY_NAME = 'dormant_activity_embedded_filters';

export const SCENARIO_DUPLICATE_SERIAL_NUMBER_NAME = 'duplicate_serial_number';
export const SCENARIO_MISSING_SERIAL_NUMBER_NAME = 'missing_serial_number';
export const SCENARIO_CHECK_MATCHES_DEPOSIT_NAME =
  'check_matches_recent_deposit';
export const SCENARIO_TARDY_CHECK_NAME = 'tardy_check';
export const SCENARIO_CHECK_OUT_OF_RANGE_NAME = 'serial_number_out_of_range';
export const SCENARIO_BUILDER_CHECK_FRAUD_RULES: string[] = [
  SCENARIO_CHECK_OUT_OF_RANGE_NAME,
  SCENARIO_CHECK_MATCHES_DEPOSIT_NAME,
  SCENARIO_TARDY_CHECK_NAME,
];
export const CHECK_FRAUD_RULES_ALL: string[] = [
  SCENARIO_DUPLICATE_SERIAL_NUMBER_NAME,
  SCENARIO_MISSING_SERIAL_NUMBER_NAME,
  ...SCENARIO_BUILDER_CHECK_FRAUD_RULES,
];

export const SCENARIO_MULTIPLE_FAILED_VALIDATION_ATTEMPTS_NAME =
  'multiple_failed_validation_attempts';
export const SCENARIO_ACH_RISK_SCORE_NAME = 'ach_risk_score';
export const SCENARIO_ACH_CONSORTIUM_NAME =
  'consortium_flagged_ach_originator_or_receiver';
export const SCENARIO_ACH_MISMATCHED_NAME = 'ach_mismatched_account_info';
export const SCENARIO_ACH_DUPLICATE_NAME = 'duplicate_ach_transaction';
export const EMPTY_CHECK_VALUE = 'EMPTY_CONSTANT_VALUE';

export const EMPTY_FIELD_VALUE: SymbolFieldValue = {
  type: 'FIELD',
  field: '',
  label: '',
  model: '',
  datatype: '',
};

export const EMPTY_VARIABLE_VALUE: VariableValue = {
  type: 'VARIABLE',
  name: '',
};

export const EMPTY_MATCHLIST_FIELD_VALUE: MatchlistFieldValue = {
  type: 'MATCHLIST_FIELD',
  matchlist_type: 'STRING',
  matchlist_id: -1,
};

export const EMPTY_CONSTANT_VALUE: ConstantValue = {
  type: 'CONSTANT',
  value: '',
};

export const EMPTY_DURATION_VALUE: DurationValue = {
  type: 'DURATION',
  value: 0,
  unit: 'DAY',
};

export const EMPTY_VALUE: ConstantValue = {
  type: 'CONSTANT',
  value: EMPTY_CHECK_VALUE,
};

export enum MetricTypeEnum {
  CONSTANT = 'CONSTANT',
  TIMESERIES = 'TIMESERIES',
  PIE = 'PIE',
}

// Note: This enum value must be the capitalised version of the backend rule metric enum
export enum MetricNameEnum {
  OPEN_ALERTS = 'OPEN_ALERTS',
  CLOSED_ALERTS = 'CLOSED_ALERTS',
  TOTAL_ALERTS = 'TOTAL_ALERTS',
  ALERTS_IN_LAST_7_DAYS = 'ALERTS_IN_LAST_7_DAYS',
  ALERTS_IN_LAST_30_DAYS = 'ALERTS_IN_LAST_30_DAYS',
  ALERTS_CREATED_OVER_TIME = 'ALERTS_CREATED_OVER_TIME',
  OPEN_CASES = 'OPEN_CASES',
  CLOSED_CASES = 'CLOSED_CASES',
  TOTAL_CASES = 'TOTAL_CASES',
  SARS_FILED = 'SARS_FILED',
  CLOSED_ALERT_TO_CASE = 'CLOSED_ALERT_TO_CASE',
  CLOSED_ALERT_TO_SAR = 'CLOSED_ALERT_TO_SAR',
  AVERAGE_ALERTS_DISPOSITIONED_DAILY = 'AVERAGE_ALERTS_DISPOSITIONED_DAILY',
  DISPOSITION_TRUE_POSITIVE = 'DISPOSITION_TRUE_POSITIVE',
  DISPOSITION_FALSE_POSITIVE_HELPFUL = 'DISPOSITION_FALSE_POSITIVE_HELPFUL',
  DISPOSITION_FALSE_POSITIVE = 'DISPOSITION_FALSE_POSITIVE',
  DISPOSITION_BREAKDOWN = 'DISPOSITION_BREAKDOWN',
  DISPOSITION_TITLE_BREAKDOWN = 'DISPOSITION_TITLE_BREAKDOWN',
}

export enum MetricIntervalEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  ALL = 'ALL',
}

export enum AlertsOverTimeIntervalEnum {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  ALL = 'ALL',
}

export const ALERTS_OVER_TIME_INTERVALS: U21ButtonGroupButtonProps<AlertsOverTimeIntervalEnum>[] =
  [
    {
      label: 'Weekly',
      value: AlertsOverTimeIntervalEnum.WEEK,
    },
    {
      label: 'Monthly',
      value: AlertsOverTimeIntervalEnum.MONTH,
    },
    {
      label: 'Yearly',
      value: AlertsOverTimeIntervalEnum.YEAR,
    },
    {
      label: 'All Time',
      value: AlertsOverTimeIntervalEnum.ALL,
    },
  ];

export const GRAPH_BASED_RULE_NAME = 'entity_link_counts';
export const WATCHLIST_RULE_NAME = 'watchlist';
export const DYNAMIC_RULE_NAME = 'custom_scenario';

export const RULE_GRAPH_CYTOSCAPE_OPTIONS: CytoscapeOptions = {
  ...BASE_CYTOSCAPE_OPTIONS,
  layout: { name: 'cose', animate: false, nodeDimensionsIncludeLabels: true },
  style: [
    {
      selector: 'node',
      style: {
        ...BASE_NODE_STYLES,
        'background-image': (node) =>
          node.data('nodeType') === 'entity'
            ? User
            : CONNECTION_TYPE_TO_ICON[node.data('linkType')],
        'background-color': (node) => node.data('color'),
        content: (node) => node.data('label'),
      },
    },
    { selector: 'edge', style: { ...BASE_EDGE_STYLES } },
    { selector: 'label', style: { ...BASE_LABEL_STYLES } },
  ],
};

export const MAX_DROPDOWN_OPTION_DESCRIPTION_LENGTH = 128;

export const EMPTY_RTM_COUNT_RESPONSE: RealTimeMonitoringCount = {
  rtr_flag_action_count: {},
  rtr_flag_action_count_without_filtering: 0,
  rtr_flag_transaction_count: {},
  rtr_flag_transaction_count_without_filtering: 0,
};
