import { SessionAgent } from 'app/modules/session/models';
import { RawDraftContentState } from 'draft-js';

export enum NotesActionTypes {
  RETRIEVE_NOTES = '@@notes/RETRIEVE_NOTES',
  RETRIEVE_NOTES_PENDING = '@@notes/RETRIEVE_NOTES_PENDING',
  RETRIEVE_NOTES_SUCCESS = '@@notes/RETRIEVE_NOTES_SUCCESS',

  SET_NOTES = '@@notes/SET_NOTES',

  TOGGLE_EDIT_MODE = '@@notes/TOGGLE_EDIT_MODE',

  TOGGLE_DELETE_MODE = '@@notes/TOGGLE_DELETE_MODE',

  ADD_NOTE = '@@notes/ADD_NOTE',
  ADD_NOTE_SUCCESS = '@@notes/ADD_NOTE_SUCCESS',

  UPDATE_NOTE = '@@notes/UPDATE_NOTE',
  UPDATE_NOTE_PENDING = '@@notes/UPDATE_NOTE_PENDING',
  UPDATE_NOTE_SUCCESS = '@@notes/UPDATE_NOTE_SUCCESS',

  DELETE_NOTE = '@@notes/DELETE_NOTE',
  DELETE_NOTE_SUCCESS = '@@notes/DELETE_NOTE_SUCCESS',
}

export interface NoteModel {
  readonly created_at: string;
  readonly author: SessionAgent;
  readonly id: number;
  readonly content: RawDraftContentState;
  readonly disposition?: string;
  editMode?: boolean;
  deleteMode?: boolean;
}

export interface ImmerNotesState {
  readonly notes: NoteModel[];
}

export interface EntityNote extends NoteModel {
  entity_external_id: string | null;
  article_type: 'ALERT' | 'CASE' | null;
  article_id: number | null;
}
