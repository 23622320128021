import {
  OrgTableColumnKeyType,
  OrgTableTypeClassifier,
  Unit21ColumnMetadata,
} from 'app/modules/tableSettings/responses';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import { FrontendColumnDefinition } from 'app/modules/tableSettings/types';

// ~~~~~ Custom org stuff ~~~~~
export const INTUIT_ENTITY_TABLE_KEY_LABEL_MAP = {
  display_status: 'Internal Status',
};

// ascendant has some custom data keys from old lumos configs that they use on their transaction tables.
// they're treated as U21 fields (even though they're custom data – lumos config stuff ig).
// we conditionally render these constants in all table config related areas only for ascendant
export const ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP = {
  'sender_instrument.custom_data.NAME': 'Sender counter party name',
  'receiver_instrument.custom_data.NAME': 'Receiver counter party name',
};

export const CHIME_TXN_EVENT_TABLE_KEY_LABEL_MAP = {
  chime_txn_account_type: 'Transaction account type',
};

export const UNIT21_ENTITY_TABLE_KEY_LABEL_MAP = {
  id: 'Unit21 ID',
  risk_score: 'Risk score',
  name_readable: 'Name',
};

export const UNIT21_TXN_EVENT_TABLE_KEY_LABEL_MAP = {
  id: 'Unit21 ID',
  updated_at: 'Updated at',
  'sender_instrument.instrument_type': 'Sender instrument type',
  'receiver_instrument.instrument_type': 'Receiver instrument type',
  'sender_instrument.instrument_subtype': 'Sender instrument subtype',
  'receiver_instrument.instrument_subtype': 'Receiver instrument subtype',
  tags: 'Tags',
  documents: 'Documents',
  outbound_flow: 'Sending',
  inbound_flow: 'Receiving',
  other_flow: 'Other directionality',
  u21_risk_score: 'Unit21 risk score',
  ...ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP,
  ...CHIME_TXN_EVENT_TABLE_KEY_LABEL_MAP,
};

export const UNIT21_ACTION_EVENT_KEY_LABEL_MAP = {
  id: 'Unit21 ID',
  created_at: 'Created at',
  entity_id: 'Entity',
  instrument_id: 'Instrument',
  device_id: 'Device',
};

export const UNIT21_INSTRUMENT_TABLE_KEY_LABEL_MAP = {
  id: 'Unit21 ID',
  created_at: 'Created at',
};

export const UNIT21_RULE_TABLE_KEY_LABEL_MAP = {
  id: 'ID',
  title: 'Title',
  status: 'Status',
  tags: 'Tags',
  created_by_readable: 'Created by',
  created_at: 'Created at',
  source: 'Source',
  scenario: 'Scenario',
  is_synchronous: 'Execution type',
  alert_priority: 'Alert priority',
  open_alerts: 'Open alerts',
  closed_alerts: 'Closed alerts',
  total_alerts: 'Total alerts',
  open_cases: 'Open cases',
  closed_cases: 'Closed cases',
  total_cases: 'Total cases',
  sars_filed: 'SARs filed',
  closed_alert_to_case: 'Alert to case ratio',
  closed_alert_to_sar: 'Alert to SAR ratio',
  alerts_in_last_7_days: 'Alerts in last 7 days',
  alerts_in_last_30_days: 'Alerts in last 30 days',
  average_alerts_dispositioned_daily: 'Average alerts dispositioned daily',
};

export const UNIT21_CASE_TABLE_KEY_LABEL_MAP = {
  id: 'ID',
  'deadline.deadline': 'Due date',
  deadline: 'Deadline',
  created_at: 'Date created',
  status: 'Case status',
  disposition: 'Disposition',
  dispositioned_at: 'Disposition date',
  title: 'Title',
  assigned_to_name: 'Assigned to',
  tags: 'Tags',
  start_date: 'Start date',
  end_date: 'End date',
  dispositioned_by: 'Dispositioned by',
  object_source: 'Source',
  subdispositions: 'Subdispositions',
  'queue.title': 'Case queue',
  watchers: 'Watchers',
};

export const UNIT21_ALERT_TABLE_KEY_LABEL_MAP = {
  id: 'ID',
  'deadline.deadline': 'Due date',
  deadline: 'Deadline',
  created_at: 'Date created',
  status: 'Alert status',
  disposition: 'Disposition',
  dispositioned_at: 'Disposition date',
  title: 'Title',
  amount_volume: 'Amount',
  assigned_to_name: 'Assigned to',
  tags: 'Tags',
  start_date: 'Start date',
  end_date: 'End date',
  alert_type: 'Alert type',
  'queue.title': 'Alert queue',
  'queue.teams': 'Teams',
  dispositioned_by: 'Dispositioned by',
  object_source: 'Source',
  subdispositions: 'Subdispositions',
  rules: 'Rules',
  alert_score: 'Alert score',
};

export const UNIT21_WATCHLIST_TABLE_KEY_LABEL_MAP = {
  id: 'ID',
  created_at: 'Created at',
  type: 'Watchlist type',
  name: 'Watchlist name',
  provider: 'Provider',
};

export const CLASSIFIER_TO_LABEL_MAP = {
  [OrgTableTypeClassifier.ENTITY]: UNIT21_ENTITY_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.EVENT]: UNIT21_TXN_EVENT_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.ACTION_EVENT]: UNIT21_ACTION_EVENT_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.INSTRUMENT]: UNIT21_INSTRUMENT_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.ALERT]: UNIT21_ALERT_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.CASE]: UNIT21_CASE_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.RULE]: UNIT21_RULE_TABLE_KEY_LABEL_MAP,
  [OrgTableTypeClassifier.WATCHLIST]: UNIT21_WATCHLIST_TABLE_KEY_LABEL_MAP,
};

export const CLASSIFIER_TO_DESCRIPTION_MAP: Record<
  OrgTableTypeClassifier,
  Record<string, string>
> = {
  [OrgTableTypeClassifier.ENTITY]: {},
  [OrgTableTypeClassifier.EVENT]: {
    outbound_flow: 'Entities and/or instruments sending money.',
    inbound_flow: 'Entities and/or instruments receiving money.',
    other_flow:
      'Entities and/or instruments neither sending nor receiving money.',
  },
  [OrgTableTypeClassifier.ACTION_EVENT]: {},
  [OrgTableTypeClassifier.INSTRUMENT]: {},
  [OrgTableTypeClassifier.ALERT]: {},
  [OrgTableTypeClassifier.CASE]: {},
  [OrgTableTypeClassifier.RULE]: {},
  [OrgTableTypeClassifier.WATCHLIST]: {},
};

const getColumnsForClassifier = (classifier: OrgTableTypeClassifier) =>
  Object.entries(CLASSIFIER_TO_LABEL_MAP[classifier]).map<
    FrontendColumnDefinition<Unit21ColumnMetadata>
  >(([key, label]) => ({
    key,
    label,
    key_type: OrgTableColumnKeyType.UNIT21 as const,
    hidden: true,
    description: CLASSIFIER_TO_DESCRIPTION_MAP[classifier][key],
  }));

export const CLASSIFIER_TO_COLUMNS = Object.values(
  OrgTableTypeClassifier,
).reduce<
  Record<
    OrgTableTypeClassifier,
    FrontendColumnDefinition<Unit21ColumnMetadata>[]
  >
>(
  (columnsMap, classifier) => ({
    ...columnsMap,
    [classifier]: getColumnsForClassifier(classifier),
  }),
  {} as Record<
    OrgTableTypeClassifier,
    FrontendColumnDefinition<Unit21ColumnMetadata>[]
  >,
);

export const CLASSIFIER_TO_LABEL = {
  [OrgTableTypeClassifier.ENTITY]: 'Entity',
  [OrgTableTypeClassifier.INSTRUMENT]: 'Instrument',
  [OrgTableTypeClassifier.EVENT]: 'Event',
  [OrgTableTypeClassifier.ACTION_EVENT]: 'Action Event',
  [OrgTableTypeClassifier.ALERT]: 'Alert',
  [OrgTableTypeClassifier.CASE]: 'Case',
  [OrgTableTypeClassifier.RULE]: 'Rule',
  [OrgTableTypeClassifier.WATCHLIST]: 'Watchlist',
};

export const UNIT21_KEY_TO_UNIT21_COLUMN_TYPE = {
  id: 'text',
  instrument_type: 'label',
  external_id: 'text',
  created_at: 'datetime',
  registered_at: 'datetime',
  status: 'label',
  unit21_status: 'label',
  instrument_subtype: 'label',
  name: 'text',
  'org.name': 'text',
  type: 'label',
  name_readable: 'text',
  risk_score: 'risk_score',
  corporate_tax_id: 'text',
  internal_entity_type: 'label',
  first_name: 'text',
  last_name: 'text',
  email_addresses: 'email_addresses',
  user_name: 'text',
  event_time: 'datetime',
  updated_at: 'datetime',
  internal_txn_type: 'label',
  amount: 'dollar',
  sender_readable: 'entity',
  'sender_instrument.external_id': 'entity',
  receiver_readable: 'entity',
  'receiver_instrument.external_id': 'entity',
  sent_currency: 'text',
  received_currency: 'text',
  'sender_instrument.instrument_type': 'label',
  'receiver_instrument.instrument_type': 'label',
  sender_id: 'label',
  receiver_id: 'label',
  'ip_address.ip_address': 'text',
  sent_amount: 'text',
  received_amount: 'text',
  'sender_instrument.name': 'entity',
  'receiver_instrument.name': 'entity',
  'sender_instrument.instrument_subtype': 'label',
  'receiver_instrument.instrument_subtype': 'label',
  ip_addresses: 'label_array',
  client_fingerprints: 'label_array',
  'deadline.deadline': 'datetime',
  start_date: 'datetime',
  end_date: 'datetime',
  dispositioned_at: 'datetime',
  alert_type: 'label',
  action_type: 'label',
  tags: 'tags',
  source: 'label',
  exchange_rate: 'amount',
  external_fee: 'amount',
  internal_fee: 'amount',
  transaction_hash: 'text',
  conversion_notes: 'text',
  date_of_birth: 'date',
  u21_risk_score: 'risk_score',
};

export const TABLE_CONFIG_TYPE_TO_U21_DATA_CLASSIFIER = {
  [TableConfigType.ENTITY_TABLE]: Unit21DataClassifier.ENTITY,
  [TableConfigType.INSTRUMENT_TABLE]: Unit21DataClassifier.INSTRUMENT,
  [TableConfigType.TXN_TABLE]: Unit21DataClassifier.EVENT,
  [TableConfigType.ACTION_EVENT_TABLE]: Unit21DataClassifier.ACTION_EVENT,
  [TableConfigType.ALERT_TABLE]: Unit21DataClassifier.ALERT,
  [TableConfigType.CASE_TABLE]: Unit21DataClassifier.CASE,
  [TableConfigType.LIVE_MODEL_TABLE]: Unit21DataClassifier.RULE,
  [TableConfigType.VALIDATING_MODEL_TABLE]: Unit21DataClassifier.RULE,
  [TableConfigType.WATCHLIST_TABLE]: Unit21DataClassifier.WATCHLIST,
};

// Mapper to decide which type of OrgTableTypeClassifier to use which Unit21DataClassifier
export const ORG_TABLE_TYPE_TO_U21_DATA_CLASSIFIER = {
  [OrgTableTypeClassifier.ACTION_EVENT]: Unit21DataClassifier.ACTION_EVENT,
  [OrgTableTypeClassifier.EVENT]: Unit21DataClassifier.EVENT,
  [OrgTableTypeClassifier.ENTITY]: Unit21DataClassifier.ENTITY,
  [OrgTableTypeClassifier.INSTRUMENT]: Unit21DataClassifier.INSTRUMENT,
  [OrgTableTypeClassifier.RULE]: Unit21DataClassifier.RULE,
  [OrgTableTypeClassifier.CASE]: Unit21DataClassifier.CASE,
  [OrgTableTypeClassifier.ALERT]: Unit21DataClassifier.ALERT,
  [OrgTableTypeClassifier.WATCHLIST]: Unit21DataClassifier.WATCHLIST,
};
