// Constants
import { TAddress, TPerson } from 'app/modules/goAML/models';
import {
  ContactForAssistance,
  ReportingFinancialInstitution,
  TransactionLocation,
} from 'app/modules/fincenCtr/models';
import { APISarValues } from 'app/modules/fincenSarNew/models';

export enum SettingsActionTypes {
  EDIT_GO_AML_TEMPLATES = '@@settings/EDIT_GO_AML_TEMPLATES',
  EDIT_GO_AML_TEMPLATES_SUCCESS = '@@settings/EDIT_GO_AML_TEMPLATES_SUCCESS',
  RETRIEVE_GO_AML_TEMPLATES = '@@settings/RETRIEVE_GO_AML_TEMPLATES',
  RETRIEVE_GO_AML_TEMPLATES_SUCCESS = '@@settings/RETRIEVE_GO_AML_TEMPLATES_SUCCESS',
}

// todo: SC-75987 move to fincenSARSettings
export interface SarConfig extends Partial<APISarValues['1']['content']> {
  page2?: APISarValues['2']['content'];
  carEnabled?: boolean;
  carSubsequentEnabled?: boolean;
  carDaysBefore?: number;
  carDaysAfter?: number;
  caseCloseDisabled?: boolean;
}

export interface EditSarConfigPayload {
  sar_config: SarConfig;
}

export interface SettingsState {
  settingsGoAMLTemplates: RetrieveGoAMLTemplatesResult[];
}

export interface RetrieveGoAMLTemplatesResult {
  content: RetrieveGoAMLTemplatesContent;
  created_at: string;
  id: number;
  org_id: number;
  tag_id?: number;
  template_type?: string;
  updated_at?: string;
}

export interface RetrieveGoAMLTemplatesContent {
  report?: RetrieveGoAMLTemplatesReport;
}

export interface RetrieveGoAMLTemplatesReport {
  rentity_branch?: string;
  rentity_id?: string;
  currency_code_local?: string;
  entity_reference?: string;
  location?: TAddress;
  reporting_person?: TPerson;
}

// Need to have these nested as we need to provide a `fieldPath` in the FinancialInstitutionForm component
export interface NestedReportingInstitution {
  filing_institution: ReportingFinancialInstitution;
  contact_office: ContactForAssistance;
}

// Need to have these nested as we need to provide a `fieldPath` in the TransactionLocation component
export interface NestedTransactionLocations {
  locations: TransactionLocation[];
}

export interface RetrieveFincenCtrTemplates {
  filing_institution?: SarFilingTemplate<NestedReportingInstitution>;
  transaction_locations?: SarFilingTemplate<NestedTransactionLocations>;
}

export interface SarFilingTemplate<T> {
  id: number;
  created_at: string;
  updated_at: string;
  org_id: number;
  tag_id: number;
  content: T;
  template_type: string;
}

export interface EditCtrTemplatePayload<T> {
  type: 'FINCEN_CTR_FILING_INSTITUTION' | 'FINCEN_CTR_TRANSACTION_LOCATIONS';
  content: T;
}

export interface EditCtrFilingInstitutionPayload
  extends EditCtrTemplatePayload<NestedReportingInstitution> {
  type: 'FINCEN_CTR_FILING_INSTITUTION';
}

export interface EditCtrTransactionLocationsPayload
  extends EditCtrTemplatePayload<NestedTransactionLocations> {
  type: 'FINCEN_CTR_TRANSACTION_LOCATIONS';
}

export interface EditGoAMLFormValues {
  content: RetrieveGoAMLTemplatesContent;
}
