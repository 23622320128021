// define all localStorage keys here to avoid key collisions

export enum LocalStorageKeys {
  AUTH0_LOGIN_ERROR = 'AUTH0_LOGIN_ERROR',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  AGENTS_TABLE_PREFERENCES = 'AGENTS_TABLE_PREFERENCES',
  ALERT_SCORE_HIDE_INFO = 'ALERT_SCORE_HIDE_INFO',
  ALERTS_TABLE_PREFERENCES = 'ALERTS_TABLE_PREFERENCES',
  ENTITY_CENTRIC_TABLE_PREFERENCES = 'ENTITY_CENTRIC_TABLE_PREFERENCES',
  CASES_TABLE_PREFERENCES = 'CASES_TABLE_PREFERENCES',
  DM_TABLE_PREFERENCES = 'DM_TABLE_PREFERENCES',
  DOCUMENTS_TABLE_PREFERENCES = 'DOCUMENTS_TABLE_PREFERENCES',
  SAR_TABLE_PREFERENCES = 'SAR_TABLE_PREFERENCES',
  TEAMS_TABLE_PREFERENCES = 'TEAMS_TABLE_PREFERENCES',
  ALERTS_GROUP_BY_PREFERENCES = 'ALERTS_GROUP_BY_PREFERENCES',
  FINCEN_CTR_GROUP_BY_PREFERENCES = 'FINCEN_CTR_GROUP_BY_PREFERENCES',
  ENTITY_CENTRIC_SORT_BY_PREFERENCES = 'ENTITY_CENTRIC_SORT_BY_PREFERENCES',
  WATCHLIST_RESULT_TABLE_PREFERENCES = 'WATCHLIST_RESULT_TABLE_PREFERENCES',
  MY_ALERT_FILTERS = 'MY_ALERT_FILTERS',
  QUEUED_ALERT_FILTERS = 'QUEUED_ALERT_FILTERS',
  ADMIN_ALERT_FILTERS = 'ADMIN_ALERT_FILTERS',
  ALERT_QUEUE_ALERT_FILTERS = 'ALERT_QUEUE_ALERT_FILTERS',
  TXN_ANALYSIS_FILTERS = 'TXN_ANALYSIS_FILTERS',
  MY_CASE_FILTERS = 'MY_CASE_FILTERS',
  QUEUED_CASE_FILTERS = 'QUEUED_CASE_FILTERS',
  ADMIN_CASE_FILTERS = 'ADMIN_CASE_FILTERS',
  CASE_QUEUE_CASE_FILTERS = 'CASE_QUEUE_CASE_FILTERS',
  ALERT_PINNED_FILTERS = 'ALERT_PINNED_FILTERS',
  CASE_PINNED_FILTERS = 'CASE_PINNED_FILTERS',
  ENTITY_DETAILS_SIDE_PANEL = 'ENTITY_DETAILS_SIDE_PANEL',
  INSTRUMENT_DETAILS_SIDE_PANEL = 'INSTRUMENT_DETAILS_SIDE_PANEL',
  ENTITY_DETAILS_TXN_FILTERS = 'ENTITY_DETAILS_TXN_FILTERS',
  INSTRUMENT_DETAILS_TXN_FILTERS = 'INSTRUMENT_DETAILS_TXN_FILTERS',
  ENTITY_DETAILS_TXN_PINNED_FILTERS = 'ENTITY_DETAILS_TXN_PINNED_FILTERS',
  INSTRUMENT_DETAILS_TXN_PINNED_FILTERS = 'INSTRUMENT_DETAILS_TXN_PINNED_FILTERS',
  ENTITY_DETAILS_TXN_TABLE_PREFERENCES = 'ENTITY_DETAILS_TXN_TABLE_PREFERENCES',
  INSTRUMENT_DETAILS_TXN_TABLE_PREFERENCES = 'INSTRUMENT_DETAILS_TXN_TABLE_PREFERENCES',
  INSTRUMENT_DETAILS_RULES_TABLE_PREFERENCES = 'INSTRUMENT_DETAILS_RULES_TABLE_PREFERENCES',
  ENTITY_DETAILS_EVENT_FILTERS = 'ENTITY_DETAILS_EVENT_FILTERS',
  ENTITY_DETAILS_EVENT_PINNED_FILTERS = 'ENTITY_DETAILS_EVENT_PINNED_FILTERS',
  ENTITY_DETAILS_EVENT_TABLE_PREFERENCES = 'ENTITY_DETAILS_EVENT_TABLE_PREFERENCES',
  ENTITY_RISK_ALERT_TABLE_PREFERENCES = 'ENTITY_RISK_ALERT_TABLE_PREFERENCES',
  ENTITY_RISK_SAR_TABLE_PREFERENCES = 'ENTITY_RISK_SAR_TABLE_PREFERENCES',
  ENTITY_RISK_CASE_TABLE_PREFERENCES = 'ENTITY_RISK_CASE_TABLE_PREFERENCES',
  ENTITY_DETAILS_RULES_TABLE_PREFERENCES = 'ENTITY_DETAILS_RULES_TABLE_PREFERENCES',
  RISK_MODEL_SIDE_PANEL = 'RISK_MODEL_SIDE_PANEL',
  SAR_FILING_SIDE_PANEL = 'SAR_FILING_SIDE_PANEL',
  CTR_FILING_SIDE_PANEL = 'CTR_FILING_SIDE_PANEL',
  SAR_SUS_ACTS_ALL_SHOWN = 'SAR_SUS_ACTS_ALL_SHOWN',
  ENTITY_TABLE_PREFERENCES = 'ENTITY_TABLE_PREFERENCES',
  ENTITY_FILTERS = 'ENTITY_FILTERS',
  ENTITY_PINNED_FILTERS = 'ENTITY_PINNED_FILTERS',
  INSTRUMENT_TABLE_PREFERENCES = 'INSTRUMENT_TABLE_PREFERENCES',
  INSTRUMENT_RISK_ALERT_TABLE_PREFERENCES = 'INSTRUMENT_RISK_ALERT_TABLE_PREFERENCES',
  SETTINGS_EXPORT_IMPORT_SIDEPANEL = 'SETTINGS_EXPORT_IMPORT_SIDEPANEL',
  INSTRUMENT_FILTERS = 'INSTRUMENT_FILTERS',
  INSTRUMENT_PINNED_FILTERS = 'INSTRUMENT_PINNED_FILTERS',
  TRANSACTION_TABLE_PREFERENCES = 'TRANSACTION_TABLE_PREFERENCES',
  TRANSACTION_FILTERS = 'TRANSACTION_FILTERS',
  TRANSACTION_PINNED_FILTERS = 'TRANSACTION_PINNED_FILTERS',
  ACTION_EVENT_TABLE_PREFERENCES = 'ACTION_EVENT_TABLE_PREFERENCES',
  ACTION_EVENT_FILTERS = 'ACTION_EVENT_FILTERS',
  ACTION_EVENT_PINNED_FILTERS = 'ACTION_EVENT_PINNED_FILTERS',
  LIVE_RULE_FILTERS = 'LIVE_RULE_FILTERS',
  VALIDATING_RULE_FILTERS = 'VALIDATING_RULE_FILTERS',
  SHADOW_RULE_FILTERS = 'SHADOW_RULE_FILTERS',
  RULE_PINNED_FILTERS = 'RULE_PINNED_FILTERS',
  MY_FILING_FILTERS = 'MY_FILING_FILTERS',
  QUEUED_FILING_FILTERS = 'QUEUED_FILING_FILTERS',
  ADMIN_FILING_FILTERS = 'ADMIN_FILING_FILTERS',
  FILING_QUEUE_FILING_FILTERS = 'FILING_QUEUE_FILING_FILTERS',
  FILING_PINNED_FILTERS = 'FILING_PINNED_FILTERS',
  QUALITY_CYCLE_TABLE_PREFERENCES = 'QUALITY_CYCLE_TABLE_PREFERENCES',
  AGENT_FILTERS = 'AGENT_FILTERS',
  AGENT_PINNED_FILTERS = 'AGENT_PINNED_FILTERS',
  DATA_SETTING_PINNED_FILTERS = 'DATA_SETTING_PINNED_FILTERS',
  STREAM_PINNED_FILTERS = 'STREAM_PINNED_FILTERS',
  TEAM_AGENT_PINNED_FILTERS = 'TEAM_AGENT_PINNED_FILTERS',
  WATCHLIST_FILTERS = 'WATCHLIST_FILTERS',
  ALERT_TRANSACTION_TABLE_PREFERENCES = 'ALERT_TRANSACTION_TABLE_PREFERENCES',
  ADVERSE_MEDIA_RISK_CATEGORY_TABLE_PREFERENCES = 'ADVERSE_MEDIA_RISK_CATEGORY_TABLE_PREFERENCES',
  ADVERSE_MEDIA_FILTERS = 'ADVERSE_MEDIA_FILTERS',
  ADVERSE_MEDIA_PINNED_FILTERS = 'ADVERSE_MEDIA_PINNED_FILTERS',
  SEARCH_ENTITY_ID_EXACT_MATCH = 'SEARCH_ENTITY_ID_EXACT_MATCH',
  LAST_ROUTE = 'last-route',
  LAST_SURVEY_TIMESTAMP = 'LAST_SURVEY_TIMESTAMP',
  FRAUD_DASHBOARD_FILTERS = 'FRAUD_DASHBOARD_FILTERS',
  NEW_DESIGN = 'NEW_DESIGN',
  ALERT_DETAILS_SIDE_PANEL = 'ALERT_DETAILS_SIDE_PANEL',
  CASE_DETAILS_SIDE_PANEL = 'CASE_DETAILS_SIDE_PANEL',
  AI_INVESTIGATION_FEEDBACK = 'AI_INVESTIGATION_FEEDBACK',
}
