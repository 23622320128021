import { ShortEntityResponse } from 'app/modules/entities/types';
import { FieldInfo } from 'app/modules/watchlists/models';

// export const TOKEN: Readonly<string> = '-';

export const LEFT_FIELD: Readonly<string> = 'LEFT';
export const RIGHT_FIELD: Readonly<string> = 'RIGHT';

export const ENTITY: Readonly<string> = 'ENTITY';
export const WATCHLIST: Readonly<string> = 'WATCHLIST';
export const FIELD_SOURCE: Readonly<string> = 'FIELD_SOURCE';
export const FIELD_GROUP: Readonly<string> = 'FIELD_GROUP';

export const INITIAL_FIELD_INFO: Readonly<FieldInfo> = {
  fields: [],
  entityUnusedFields: [],
  watchlistUnusedFields: [],
  formattedData: {},
};

export const INITIAL_WATCHLIST_ENTITY: Readonly<ShortEntityResponse> = {
  created_at: '2023-12-11T13:50:01+00:00',
  status: 'Active',
  internal_entity_type: 'person',
  risk_score: 0,
  internal_status: null,
  entity_type: 'USER',
  external_id: "John Doe's external ID",
  formatted_data: {},
  id: '911',
  name_readable: 'John Doe',
  type: 'USER',
};

export enum AdverseMediaRiskClassification {
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW',
}

export const NEWS_CATEGORY_OTHERS: Readonly<string> = 'Others';
