import { useState } from 'react';
import styled from 'styled-components';

import { U21Alert, U21Button } from 'app/shared/u21-ui/components';
import { useSelector } from 'react-redux';
import { selectIsSandbox2Deprecated } from 'app/shared/featureFlags/selectors';
import { SANDBOX_2_DEPRECATION_DOCS } from 'app/shared/constants/docs';

export const Sandbox2DeprecationAlert = () => {
  const [hidden, setHidden] = useState(false);
  const isSandbox2Deprecated = useSelector(selectIsSandbox2Deprecated);
  if (!isSandbox2Deprecated) {
    return null;
  }

  return (
    <BannerAlert hidden={hidden} onClose={() => setHidden(true)}>
      Heads up! We will be deprecating this sandbox environment by September
      30th, 2024. If you would like to migrate your data to a new instance,
      please{' '}
      <U21Button
        color="primary"
        href={SANDBOX_2_DEPRECATION_DOCS}
        variant="text"
      >
        click here to learn more
      </U21Button>
      , or reach out to your CSM.
    </BannerAlert>
  );
};

// styling matches ToastComponent
const BannerAlert = styled(U21Alert)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 12px;
  box-shadow: ${(props) =>
    props.theme.customShadows[props.severity || 'error']};
`;
