import { useMutation } from '@tanstack/react-query';
import { sendErrorToast } from 'app/shared/toasts/actions';
import { post } from 'app/shared/utils/fetchr';
import { useDispatch } from 'react-redux';

export const useQueueNetworkAnalysis = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (entityExternalId: string) =>
      post(`/entities/network-analysis/${entityExternalId}>/queue`),
    onError: () =>
      dispatch(
        sendErrorToast('Failed to queue network analysis. Please try again.'),
      ),
  });
};
