import {
  U21Alert,
  U21Button,
  U21Loading,
  U21NoData,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Filters } from 'app/modules/networkAnalysis/components/Filters';
import { useEntityLinks } from 'app/modules/networkAnalysis/queries/useEntityLinks';
import {
  filterData,
  graphifyNetworkAnalysisData,
  resetSelectedNodesAndEdges,
} from 'app/modules/networkAnalysis/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { selectLinkAnalysisFilters } from 'app/modules/networkAnalysis/selectors';
import { isEmpty } from 'lodash';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { useHistory, useLocation } from 'react-router';
import {
  NETWORK_ANALYSIS_CYTOSCAPE_OPTIONS,
  LINK_ANALYSIS_LINK_TYPES,
  LINK_SECTION_MOUNTED,
  LINK_SECTION_UNMOUNTED,
  TRANSACTIONS_SECTION_HASH,
  ELEMENT_KEYS_TO_UPDATE,
} from 'app/modules/networkAnalysis/constants';
import {
  U21NetworkGraph,
  U21NetworkGraphProps,
} from 'app/shared/components/Graphs/U21NetworkGraph';
import { selectEntityLinkAnalysisReport } from 'app/modules/entities/selectors';
import { Core, EdgeSingular, EventObject, NodeSingular } from 'cytoscape';
import {
  DivRefObject,
  LinkSectionMounted,
  LinkSectionUnMounted,
} from 'app/modules/networkAnalysis/types';
import {
  IconAffiliate,
  IconCircleDot,
  IconGridDots,
  IconSpace,
  IconSpaceOff,
} from '@u21/tabler-icons';
import { queueEntityLinkAnalysis } from 'app/modules/entities/actions';
import LinkAnalysisJobState from 'app/modules/linkAnalysis/components/LinkAnalysisJobState';
import { NetworkLinks } from 'app/modules/networkAnalysis/components/NetworkLinks';
import {
  FullEntityResponse,
  ShortEntityResponse,
} from 'app/modules/entities/types';
import { isLinkAnalysisPending } from 'app/modules/linkAnalysis/utils';
import {
  CONCENTRIC_LAYOUT_OPTIONS,
  GRID_LAYOUT_OPTIONS,
} from 'app/shared/components/Graphs/constants';
import { selectNetworkAnalysisOnDeltaLake } from 'app/shared/featureFlags/selectors';
import { useQueueNetworkAnalysis } from 'app/modules/networkAnalysis/queries/useQueueNetworkAnalysis';

const GRAPH_ALERT_HINTS = [
  'Command/control + click an entity to open its details in a sidebar.',
  'Command/control + click a link or transaction to scroll to the relevant section below the graph.',
  'Command/control + scroll to zoom in and out.',
];

interface NetworkAnalysisProps {
  entity: FullEntityResponse | ShortEntityResponse;
}

export const NetworkAnalysis = ({ entity }: NetworkAnalysisProps) => {
  const { hash, pathname } = useLocation();
  const filters = useSelector(selectLinkAnalysisFilters);
  const { data, isLoading, refetch } = useEntityLinks(
    entity.id.toString(),
    entity.external_id,
  );
  const { mutate: queueNetworkAnalysisOnDeltaLake } = useQueueNetworkAnalysis();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const linkAnalysis = useSelector(selectEntityLinkAnalysisReport);
  const hasPendingJob = useMemo<boolean>(() => {
    return !!linkAnalysis && isLinkAnalysisPending(linkAnalysis);
  }, [linkAnalysis]);
  const [spaceNodes, setSpaceNodes] = useState<boolean>(
    Object.keys(data?.entities ?? {}).length < 20,
  );
  const [isGridLayout, setIsGridLayout] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<Set<string>>(
    new Set(),
  );
  const networkAnalysisOnDeltaLake = useSelector(
    selectNetworkAnalysisOnDeltaLake,
  );
  const handleGraphClick: U21NetworkGraphProps['handleGraphClick'] =
    useCallback(
      (e: EventObject, cy: Core | null) => {
        const group = e.target?.group?.();
        const isMetaClick = e.originalEvent.ctrlKey || e.originalEvent.metaKey;
        if (group === 'nodes') {
          const node: NodeSingular = e.target;
          const { id, nodeType: type, ...rest } = node.data();
          resetSelectedNodesAndEdges(cy);
          for (const edge of node.connectedEdges()) {
            // highlight node's connected edges
            edge.data('selected', true);
            // highlight node's edges' connected nodes
            for (const el of edge.connectedNodes()) {
              el.data('selected', true);
              if (el.data('nodeType') === 'link') {
                // if node is a link, make sure that edges connected to base entity are also selected
                cy
                  ?.getElementById(`${el.data('id')}___${entity.id}`)
                  ?.data('selected', true);
              }
            }
          }
          // cmd/ctrl click should open sidebar or scroll to related link section
          if (isMetaClick && type === 'entity') {
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id: networkAnalysisOnDeltaLake ? 0 : id,
                  externalId: networkAnalysisOnDeltaLake
                    ? rest.external_id
                    : undefined,
                },
              }),
            );
          } else if (isMetaClick) {
            const linkHash =
              node.data('type') === LINK_ANALYSIS_LINK_TYPES.TRANSACTION
                ? TRANSACTIONS_SECTION_HASH
                : id;
            history.replace(`${pathname}#${linkHash}`);
          }
        }
        // if target is an transaction edge, highlight it and its nodes
        else if (group === 'edges') {
          resetSelectedNodesAndEdges(cy);
          const edge: EdgeSingular = e.target;
          edge.data('selected', true);
          for (const el of edge.connectedNodes()) {
            el.data('selected', true);
          }
          // if edge has a label it's a transaction
          if (edge.data('label') && isMetaClick) {
            history.replace(`${pathname}#${TRANSACTIONS_SECTION_HASH}`);
          }
        }
      },
      [reduxDispatch, history, pathname, entity.id, networkAnalysisOnDeltaLake],
    );

  /**
   * hashReferences: Map form URL hash -> DOM node
   * Not in redux because DOM node is not serializable
   */
  const [hashReferences, dispatch] = useReducer(
    (
      state: Record<string, DivRefObject>,
      action:
        | {
            type: LinkSectionMounted;
            payload: Record<string, DivRefObject>;
          }
        | {
            type: LinkSectionUnMounted;
            payload: { sectionHash: string };
          },
    ) => {
      if (action.type === LINK_SECTION_MOUNTED) {
        return { ...state, ...action.payload };
      }
      if (action.type === LINK_SECTION_UNMOUNTED) {
        const newState = { ...state };
        delete newState[action.payload.sectionHash];
        return newState;
      }
      return state;
    },
    {},
  );

  /**
   * Effect to scroll to url-hash link element
   */
  useEffect(() => {
    if (hash) {
      const parsedHash = hash.slice(1).replaceAll('%20', ' ');
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        newSet.add(parsedHash);
        return newSet;
      });
      const sectionToScroll = hashReferences[parsedHash];
      setTimeout(() => {
        if (sectionToScroll && sectionToScroll.current) {
          sectionToScroll.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }, [hash, hashReferences]);

  const filteredData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return filterData(data, filters, entity.id.toString());
  }, [data, filters, entity.id]);

  const elements = useMemo(
    () =>
      data && filteredData
        ? graphifyNetworkAnalysisData(data, entity, filteredData)
        : null,
    [data, entity, filteredData],
  );

  const [showHints, setShowHints] = useState<boolean>(true);
  const entityHasLinks: boolean = useMemo(
    () => !!data && (!isEmpty(data.links) || !isEmpty(data.transactions)),
    [data],
  );

  const layoutOptions = useMemo(
    () => ({
      ...(isGridLayout ? GRID_LAYOUT_OPTIONS : CONCENTRIC_LAYOUT_OPTIONS),
      nodeDimensionsIncludeLabels: spaceNodes,
    }),
    [spaceNodes, isGridLayout],
  );

  const graphActions: U21NetworkGraphProps['actions'] = useMemo(
    () => [
      {
        key: 'spaceNodes',
        children: spaceNodes ? <IconSpaceOff /> : <IconSpace />,
        tooltip: spaceNodes
          ? 'Remove additional spacing between nodes'
          : 'Add additional spacing between nodes',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setSpaceNodes((prev) => !prev),
      },
      {
        key: 'alterLayout',
        children: isGridLayout ? <IconCircleDot /> : <IconGridDots />,
        tooltip: isGridLayout ? 'Use concentric layout' : 'Use grid layout',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setIsGridLayout((prev) => !prev),
      },
    ],
    [spaceNodes, isGridLayout],
  );
  return (
    <U21Spacer spacing={4}>
      {entityHasLinks && (
        <Filters
          entityId={entity.id.toString()}
          entityExternalId={entity.external_id}
        />
      )}
      <U21Section
        title="Network Analysis"
        titleIcon={<IconAffiliate />}
        action={
          <U21Button
            onClick={() =>
              networkAnalysisOnDeltaLake
                ? queueNetworkAnalysisOnDeltaLake(entity.external_id)
                : reduxDispatch(queueEntityLinkAnalysis(entity.id))
            }
            disabled={hasPendingJob}
          >
            Run New Analysis
          </U21Button>
        }
      >
        <U21Spacer spacing={2}>
          {/* TODO SC-76102: create new component for network analysis polling */}
          {networkAnalysisOnDeltaLake ? null : (
            <LinkAnalysisJobState
              entityId={entity.id}
              onComplete={() => refetch()}
            />
          )}
          {isLoading ? (
            <U21Loading />
          ) : (
            <>
              {entityHasLinks ? (
                <U21Alert
                  hidden={!showHints}
                  severity="info"
                  title="How to use this chart:"
                  onClose={() => {
                    setShowHints(false);
                  }}
                >
                  {GRAPH_ALERT_HINTS.map((hint) => (
                    <U21Typography key={hint} variant="body2">
                      <li>{hint}</li>
                    </U21Typography>
                  ))}
                </U21Alert>
              ) : (
                <U21Alert severity="info">
                  This entity has no linked entities. Try running a new
                  analysis.
                </U21Alert>
              )}
              {elements ? (
                <U21NetworkGraph
                  cytoscapeOptions={NETWORK_ANALYSIS_CYTOSCAPE_OPTIONS}
                  elements={elements}
                  elementKeysToUpdate={ELEMENT_KEYS_TO_UPDATE}
                  handleGraphClick={handleGraphClick}
                  layoutOptions={layoutOptions}
                  actions={graphActions}
                />
              ) : (
                <U21NoData />
              )}
            </>
          )}
        </U21Spacer>
      </U21Section>
      {!!data && !!filteredData && (
        <NetworkLinks
          data={data}
          filteredData={filteredData}
          dispatch={dispatch}
          expandedSections={expandedSections}
          setExpandedSections={setExpandedSections}
        />
      )}
    </U21Spacer>
  );
};
