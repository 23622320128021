// Models
import { AdminActionTypes, OrgDetails } from 'app/modules/admin/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrieveOrgs = () => {
  return createAction(AdminActionTypes.RETRIEVE_ORGS);
};

export const retrieveOrgsSuccess = (payload: OrgDetails[]) => {
  return createAction(AdminActionTypes.RETRIEVE_ORGS_SUCCESS, payload);
};

const AdminActionsList = {
  retrieveOrgs,
  retrieveOrgsSuccess,
};

export type AdminActions = ActionsUnion<typeof AdminActionsList>;
