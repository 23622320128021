import auth0 from 'auth0-js';
import Cookies from 'js-cookie';

// Models
import BaseAuthService from 'app/modules/session/authServices/baseAuthService';
import { Auth0AuthArgs } from 'app/modules/session/models';

// Selectors
import { clearSessionStorage } from 'app/shared/utils/sessionStorage';

// Utils
import { consoleError } from 'app/shared/utils/console';
import { heapLogout } from 'app/shared/utils/heap';

// Constants
import { AUTH_CONFIG } from 'app/modules/session/auth0Config';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// auth0 SPA + API pattern from
// https://auth0.com/docs/architecture-scenarios/spa-api/part-3
export class Auth0Auth extends BaseAuthService {
  scopes: string;

  expiresAt = new Date().getTime(); // epoch (seconds since unix birth)

  requestedScopes = 'openid profile';

  provider = 'auth0';

  hasRefreshedToken = true; // auth0 handles this so we can assume true

  loadingFlags = false;

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain ? AUTH_CONFIG.domain : '',
    clientID: AUTH_CONFIG.clientId ? AUTH_CONFIG.clientId : '',
    redirectUri: AUTH_CONFIG.loginRedirectUrl,
    audience: AUTH_CONFIG.apiAudience,
    responseType: 'token id_token',
    scope: this.requestedScopes,
  });

  private authorizeArgs: Auth0AuthArgs = {
    scope: 'openid profile',
    accessType: 'offline',
  };

  connection?: string;

  // redirects to auth0
  public authorize = () => {
    const { connection, authorizeArgs } = this;
    this.auth0.authorize({ ...authorizeArgs, connection });
  };

  public login = (): Promise<void> => {
    return new Promise((resolve) => {
      this.auth0.checkSession(
        {
          audience: AUTH_CONFIG.apiAudience,
        },
        (err, authResult) => {
          if (!err) {
            this.setSession(authResult);
            resolve();
            return;
          }
          // allows BE to receive google refresh token.
          // https://community.auth0.com/t/cant-get-google-refresh-token-using-auth0-js/11756
          // refresh token is saved on the BE and NOT returned to the FE
          this.authorize();
        },
      );
    });
  };

  public renewSession = (callBack: (() => void) | null = null) => {
    this.auth0.checkSession(
      {
        audience: AUTH_CONFIG.apiAudience,
      },
      (err, result) => {
        if (!err) {
          this.setSession(result);
        } else if (err.code === 'access_denied') {
          // If the login is denied redirect to the login page with error message
          const errorMessage = err.error_description || 'Access Denied';
          localStorage.setItem(
            LocalStorageKeys.AUTH0_LOGIN_ERROR,
            errorMessage,
          );
          this.logout(false);
          return;
        }

        if (callBack) {
          callBack();
        }
      },
    );
  };

  private scheduleRenewal = () => {
    const timeout /* milliseconds */ = this.expiresAt - new Date().getTime();
    if (timeout > 0) {
      if (this.tokenRenewalTimeout) {
        clearTimeout(this.tokenRenewalTimeout);
      }

      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  };

  private setSession = (authResult: auth0.Auth0DecodedHash): void => {
    // convert seconds to milliseconds
    const expiresIn = (authResult.expiresIn || 0) * 1000;
    // Set the time that the access token will expire at
    const expiresAt = expiresIn + new Date().getTime();
    const {
      accessToken,
      scope,
      idTokenPayload: { sub },
    } = authResult;

    if (accessToken) {
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);
    }

    this.scopes = scope || '';
    this.expiresAt = expiresAt;

    this.setConnection(authResult);

    try {
      // Only schedule token renewal if user is logged in via google-oauth2
      if (sub.startsWith('google-oauth2|')) {
        this.scheduleRenewal();
      }
    } catch (err) {
      consoleError({
        error: `Encountered error accessing auth result for scheduling token renewal. ${err.message}`,
      });
    }

    this.scheduleTokenPing();
    this.scheduleSleepCheck();
  };

  public logout = async (showLogoutModal: boolean = false) => {
    clearTimeout(this.tokenRenewalTimeout);
    clearInterval(this.tokenPingInterval);
    clearInterval(this.sleepCheckInternal);
    clearSessionStorage();
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((key) => {
      if (key.includes('auth0')) {
        Cookies.remove(key);
      }
    });

    const path = showLogoutModal ? '/agent-login?logout=true' : '/agent-login';
    this.auth0.logout({ returnTo: `${AUTH_CONFIG.logoutRedirectUrl}${path}` });

    // Analytics
    heapLogout();
  };

  public isAuthenticated = (): boolean => {
    // Check whether the current time is past the access token's expiry time
    return new Date().getTime() < this.expiresAt;
  };

  private setConnection = async ({
    accessToken,
  }: auth0.Auth0DecodedHash): Promise<void> => {
    if (this.connection) {
      return;
    }

    const connection = await fetch(`${AUTH_CONFIG.apiBaseUrl}/get-connection`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => response.json());

    this.connection = connection;
  };
}
