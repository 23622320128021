import { ConfiguredFlow } from 'app/modules/dataSettings/types';
import { RuleStatus } from 'app/modules/rules/models';

export enum Unit21DataClassifier {
  ENTITY = 'ENTITY',
  INSTRUMENT = 'INSTRUMENT',
  EVENT = 'EVENT',
  ALERT = 'ALERT',
  CASE = 'CASE',
  RULE = 'RULE',
  ACTION_EVENT = 'ACTION_EVENT',
  ADDRESS = 'ADDRESS',
  WATCHLIST = 'WATCHLIST',
}

export enum DataSettingFieldType {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  LIST = 'LIST',
  ENUM = 'ENUM',
  BOOLEAN = 'BOOLEAN',
  ENTITY_REFERENCE = 'ENTITY_REFERENCE',
  INSTRUMENT_REFERENCE = 'INSTRUMENT_REFERENCE',
  JSON = 'JSON',
  ANY = 'ANY',
}

export type CustomDataRelatedRules = {
  id: number;
  title: string;
  status: RuleStatus;
};

export enum DataSettingsKeyType {
  NATIVE = 'NATIVE',
  CUSTOM = 'CUSTOM',
}

export enum SemanticTypes {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  ACH_SEC_CODE = 'ACH_SEC_CODE',
  ACH_RETURN_CODE = 'ACH_RETURN_CODE',
  CHECK_MEMO = 'CHECK_MEMO',
  CHECK_NUMBER = 'CHECK_NUMBER',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  INSTITUTION_NUMBER = 'INSTITUTION_NUMBER',
  JH_CHECK_IMAGE_NUMBER = 'JH_CHECK_IMAGE_NUMBER',
  MCC_CODE = 'MCC_CODE',
  NACHA_DESCRIPTION = 'NACHA_DESCRIPTION',
  NAICS_CODE = 'NAICS_CODE',
  ROUTING_NUMBER = 'ROUTING_NUMBER',
  TRACE_ID = 'TRACE_ID',
  URL = 'URL',
}

// these are lower-case for compadibility with dmb stuff

export enum TxnEventNativeFields {
  ADDRESS = 'address',
  AMOUNT = 'amount',
  CLIENT_FINGERPRINT = 'client_fingerprint',
  EVENT_ID = 'event_id',
  EVENT_TIME = 'event_time',
  EXCHANGE_RATE = 'exchange_rate',
  EXTERNAL_FEE = 'external_fee',
  FEE = 'fee',
  IP_ADDRESS = 'ip_address',
  RECEIVED_AMOUNT = 'received_amount',
  RECEIVED_CURRENCY = 'received_currency',
  SENT_AMOUNT = 'sent_amount',
  SENT_CURRENCY = 'sent_currency',
  STATUS = 'status',
  TRANSACTION_HASH = 'transaction_hash',
  TYPE = 'type',
  CONVERSION_NOTES = 'conversion_notes',
  SENDER_ENTITY_ID = 'sender_entity_id',
  RECEIVER_ENTITY_ID = 'receiver_entity_id',
  SENDER_INSTRUMENT_ID = 'sender_instrument_id',
  RECEIVER_INSTRUMENT_ID = 'receiver_instrument_id',
}

export enum ActionEventNativeFields {
  ACTION_CONTENT = 'action_content',
  ACTION_TYPE = 'action_type',
  ADDRESS = 'address',
  CLIENT_FINGERPRINT = 'client_fingerprint',
  EVENT_ID = 'event_id',
  EVENT_TIME = 'event_time',
  IP_ADDRESS = 'ip_address',
  STATUS = 'status',
  ENTITY_ID = 'entity_id',
  INTRUMENT_ID = 'instrument_id',
}

export enum EntityNativeFields {
  ACCOUNT_HOLDER_NAME = 'account_holder_name',
  ADDRESSES = 'addresses',
  BUSINESS_NAME = 'business_name',
  CLIENT_FINGERPRINTS = 'client_fingerprints',
  CORPORATE_TAX_ID = 'corporate_tax_id',
  DATE_OF_BIRTH = 'date_of_birth',
  DOING_BUSINESS_AS = 'doing_business_as',
  EMAIL_ADDRESSES = 'email_addresses',
  ENTITY_SUBTYPE = 'entity_subtype',
  ENTITY_TYPE = 'entity_type',
  EXTERNAL_ID = 'external_id',
  FIRST_NAME = 'first_name',
  GENDER = 'gender',
  IP_ADDRESSES = 'ip_addresses',
  LAST_NAME = 'last_name',
  MIDDLE_NAME = 'middle_name',
  PHONE_NUMBERS = 'phone_numbers',
  PHYSICAL_IDS = 'physical_ids',
  REGISTERED_AT = 'registered_at',
  REGISTERED_COUNTRY = 'registered_country',
  REGISTERED_STATE = 'registered_state',
  SSN = 'ssn',
  STATUS = 'status',
  USER_NAME = 'user_name',
  WEBSITE = 'website',
}

export enum InstrumentNativeFields {
  ADDRESSES = 'addresses',
  CLIENT_FINGERPRINTS = 'client_fingerprints',
  INSTRUMENT_ID = 'instrument_id',
  INSTRUMENT_SUBTYPE = 'instrument_subtype',
  INSTRUMENT_TYPE = 'instrument_type',
  IP_ADDRESSES = 'ip_addresses',
  NAME = 'name',
  REGISTERED_AT = 'registered_at',
  SOURCE = 'source',
  STATUS = 'status',
}

export enum AddressNativeFields {
  BUILDING_NUMBER = 'building_number',
  UNIT_NUMBER = 'unit_number',
  STREET = 'street',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postal_code',
  COUNTRY = 'country',
  TYPE = 'type',
}

export type AllNativeFields =
  | ActionEventNativeFields
  | TxnEventNativeFields
  | InstrumentNativeFields
  | EntityNativeFields
  | AddressNativeFields;

export interface BaseDataSettingsConfigResponse<
  C extends Unit21DataClassifier = Unit21DataClassifier,
> {
  configured_flow: ConfiguredFlow | null;
  data_type: DataSettingFieldType;
  description?: string;
  enum_set: string[];
  exported: boolean;
  id: number;
  last_customer_update_time: string | null;
  matchable?: boolean;
  recent_values: string[];
  formatted_recent_values: FormattedDataItem[];
  rendering_options?: RenderingOptionsResponse;
  rules?: CustomDataRelatedRules[];
  unit21_data_classifier: C;
  user_facing_label?: string;
  censorship_setting: CensorshipSetting;
  team_ids_with_censorship_read_permission: number[];
  agent_ids_with_censorship_read_permission: number[];
}

export interface CustomDataSettingsConfigResponse
  extends BaseDataSettingsConfigResponse {
  key_type: DataSettingsKeyType.CUSTOM;
  key_path: string[];
  semantic_type?: SemanticTypes;
}

export interface BaseNativeDataSettingsConfigResponse<
  C extends Unit21DataClassifier,
  K extends AllNativeFields,
> extends BaseDataSettingsConfigResponse<C> {
  key_type: DataSettingsKeyType.NATIVE;
  native_field: string;
  native_key: K;
}

export type NativeActionEventDataSettingsConfigResponse =
  BaseNativeDataSettingsConfigResponse<
    Unit21DataClassifier.ACTION_EVENT,
    ActionEventNativeFields
  >;
export type NativeTxnEventDataSettingsConfigResponse =
  BaseNativeDataSettingsConfigResponse<
    Unit21DataClassifier.EVENT,
    TxnEventNativeFields
  >;
export type NativeEntityDataSettingsConfigResponse =
  BaseNativeDataSettingsConfigResponse<
    Unit21DataClassifier.ENTITY,
    EntityNativeFields
  >;
export type NativeInstrumentDataSettingsConfigResponse =
  BaseNativeDataSettingsConfigResponse<
    Unit21DataClassifier.INSTRUMENT,
    InstrumentNativeFields
  >;
export type NativeAddressDataSettingsConfigResponse =
  BaseNativeDataSettingsConfigResponse<
    Unit21DataClassifier.ADDRESS,
    AddressNativeFields
  >;

export type NativeDataSettingsConfigResponse =
  | NativeActionEventDataSettingsConfigResponse
  | NativeTxnEventDataSettingsConfigResponse
  | NativeEntityDataSettingsConfigResponse
  | NativeInstrumentDataSettingsConfigResponse
  | NativeAddressDataSettingsConfigResponse;

export type OrgDataSettingsConfig =
  | CustomDataSettingsConfigResponse
  | NativeDataSettingsConfigResponse;

export interface OrgDataSettingsConfigResponse {
  data: OrgDataSettingsConfig[];
}

export interface OrgDataSettingsConfigAddEditResponse {
  data: OrgDataSettingsConfig;
}

export enum FormattedDataCensorshipStatus {
  UNCENSORED = 'UNCENSORED',
  CENSORED = 'CENSORED',
  UNCENSORABLE = 'UNCENSORABLE',
}

// for use in cgdo response objects' `formatted_data` dict
// `formatted_value` is the value returned after being parsed according to its type in data settings
// e.g. a datetime being returned in ISO-8601 format
export interface FormattedDataItem {
  formatted_value: any;
  is_parseable: boolean;
  raw_value: any;
  censorship: FormattedDataCensorshipStatus;
}

export type FormattedData = Record<number, FormattedDataItem>;

export type FormattedRecentValues = Record<number, FormattedDataItem[]>;

export interface EnumMappingItem {
  description?: string;
  color?: string;
}

export type RenderingOptionsResponse = {
  precision?: number | null;
  timezone?: string | null;
  is_url?: boolean;
  url_prefix?: string;
  url_postfix?: string;
  enum_mapping?: Record<string, EnumMappingItem | undefined>;
  reference_display_fields?: string[];
  // render_in_color?: boolean; Not yet implemented
};

export enum CensorshipSetting {
  ALWAYS_VISIBLE = 'ALWAYS_VISIBLE',
  CENSORED_BUT_VIEWABLE = 'CENSORED_BUT_VIEWABLE',
  CENSORED = 'CENSORED',
  // ENCRYPTED = 'ENCRYPTED'
}
