// Models
import {
  CompositeQueryTreeNode,
  DmObjectToFlag,
  DmOperator,
  IRSchema,
  QueryTreeNode,
} from 'app/modules/rulesAdvanced/types';
import {
  AndOrCondition,
  Condition,
  DropdownBuilderFormValues,
  FormOperand,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import { Operand } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { FormApi } from 'final-form';

// Constants
import {
  ConditionalOperators,
  DropdownBuilderFormFields,
  OBJECT_TO_FLAG_TO_MODEL,
} from 'app/modules/detectionModels/constants';

// Utils
import { isTagOperator } from 'app/modules/detectionModels/helpers';

// NOTE: Dropdown Builder helper functions
// Extracted from helpers.tsx to avoid circular dependencies
export const generateEmptyIrRule = (object: DmObjectToFlag): IRSchema => {
  return {
    facts: [],
    rule_condition: {
      type: 'rule_condition_composite',
      operands: [],
      operator: 'AND',
    },
    object,
    field_to_flag: {
      type: 'FLAG_FIELD',
      field: `${object === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
      model: OBJECT_TO_FLAG_TO_MODEL[object],
      values: [],
    },
  };
};

export const isAndOrCondition = (
  condition: any,
): condition is AndOrCondition => {
  return Object.hasOwn(condition, DropdownBuilderFormFields.CONDITIONS);
};

export const isCondition = (condition: any): condition is Condition => {
  return Object.hasOwn(condition, DropdownBuilderFormFields.LEFT_OPERAND);
};

export const isCompositeType = (
  condition: FormOperand,
): condition is Condition | AndOrCondition => {
  return (
    (isCondition(condition) || isAndOrCondition(condition)) &&
    (condition?.type === 'rule_condition_composite' ||
      condition?.type === 'COMPOSITE')
  );
};

// export const convertConditionsToQueryTreeNodes = ( TODO: chore/sc-76764/create-util-function-to-convert-fe-payload
//   condition: FormOperand,
// ): QueryTreeNode => {
//   if (!condition) return null;
//   if (isCompositeType(condition)) {
//     if (Object.hasOwn(condition, DropdownBuilderFormFields.CONDITIONS)) {
//      return condition[DropdownBuilderFormFields.CONDITIONS].map((nestedCondition) => convertConditionsToQueryTreeNodes(nestedCondition))
//     }
//     const { type, operator, leftOperand, rightOperand, betweenOperand } =
//       condition as Condition;

//     const operands = [
//       leftOperand,
//       convertConditionsToQueryTreeNodes(rightOperand),
//     ];
//     if (betweenOperand)
//       operands.push(convertConditionsToQueryTreeNodes(betweenOperand));

//     return {
//       type,
//       operands,
//       operator,
//       meta_ui: {
//         input_choice: 'dropdown',
//         ui_operator: operator,
//       },
//     };
//   }
//   return condition;
// };

// export const generateIRSchemaFromForm = ( TODO: chore/sc-76764/create-util-function-to-convert-fe-payload
//   value: DropdownBuilderFormValues,
// ): IRSchema => {
//   const {
//     condition,
//     eventSubTypes,
//     facts,
//     objectToFlag,
//   } = value;
//   const ruleCondition: CompositeQueryTreeNode = {
//     type: 'rule_condition_composite',
//     operator: condition.operator ?? 'AND',
//     operands: [],
//   };

//   ruleCondition.operands = condition.conditions.map((nestedCondition) =>
//     convertConditionsToQueryTreeNodes(nestedCondition),
//   );

//   return {
//     facts,
//     field_to_flag: {
//       type: 'FLAG_FIELD',
//       field: `${objectToFlag === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
//       model: OBJECT_TO_FLAG_TO_MODEL[objectToFlag],
//       values: eventSubTypes,
//     },
//     object: objectToFlag,
//     rule_condition: ruleCondition,
//   };
// };

export const generateNestedOperandKey = (groupIndex: number[]) => {
  let operatorKey: string = ``;
  groupIndex.forEach((gIndex) => {
    operatorKey = operatorKey.concat(
      `${operatorKey === '' ? '' : '.'}operands[${gIndex}]`,
    );
  });
  return operatorKey;
};

export const extractFactNames = (
  ruleCondition: CompositeQueryTreeNode,
): Set<string> => {
  const foundFacts: QueryTreeNode[] = ruleCondition.operands.filter(
    (operand) => operand?.type === 'VARIABLE',
  );
  const factNames: Set<string> = new Set();
  if (foundFacts.length) {
    foundFacts.forEach((variable) => {
      if (variable?.type === 'VARIABLE') factNames.add(variable.name);
    });
  }
  return factNames;
};

export const extractFactNamesFromGroup = (
  groupCondition: CompositeQueryTreeNode,
): Set<string> => {
  const factNames: Set<string> = new Set();
  groupCondition.operands.forEach((operand) => {
    if (operand?.type === 'rule_condition_composite') {
      if (isTagOperator(operand.operator))
        extractFactNamesFromGroup(operand).forEach((factName) =>
          factNames.add(factName),
        );
      // NOTE: still a "group" condition
      else
        extractFactNames(operand).forEach((factName) =>
          factNames.add(factName),
        );
    }
  });
  return factNames;
};

export const comparisonOperatorSideEffect = (
  operand: Operand | undefined,
): { uiOperator: DmOperator; operator: DmOperator } | undefined => {
  if (!operand) return undefined;
  let res;
  if (
    operand.dropdownValues?.length ||
    ['enum', 'multiselect', 'select'].includes(operand.operandType)
  ) {
    res = {
      uiOperator: ConditionalOperators.IN,
      operator: ConditionalOperators.ANY_IN,
    };
  } else if (['date', 'datetime'].includes(operand.operandType)) {
    res = {
      uiOperator: ConditionalOperators.GT,
      operator: ConditionalOperators.GT,
    };
  } else {
    res = {
      uiOperator: ConditionalOperators.EQ,
      operator: ConditionalOperators.EQ,
    };
  }

  return res;
};

export const resetRTMDropdownFormValues = (
  form: FormApi<DropdownBuilderFormValues, Partial<DropdownBuilderFormValues>>,
  objectToFlag: DmObjectToFlag,
) => {
  form.change(DropdownBuilderFormFields.OBJECT_TO_FLAG, objectToFlag);
  form.change(DropdownBuilderFormFields.EVENT_SUB_TYPES, []);
  form.change(DropdownBuilderFormFields.CONDITION, {
    type: 'rule_condition_composite',
    operator: 'AND',
    conditions: [
      {
        type: 'rule_condition_composite',
        operator: 'EQ',
        leftOperand: {
          operandType: 'select',
          value: {
            type: 'FIELD',
            field: '',
            datatype: '',
            model: '',
          },
        },
        rightOperand: null,
        betweenOperand: null,
      },
    ],
  });
  form.change('facts', []);
};
