import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';
import {
  ActionEventTypeClassification,
  ClassifiableCGDOs,
  Classifications,
  EntityTypeClassification,
  InstrumentTypeClassification,
  TxnEventTypeClassification,
  TxnMovementType,
} from 'app/modules/typeClassification/types';
import {
  U21ButtonGroupButtonProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

export const CLASSIFIABLE_CGDOS: ClassifiableCGDOs[] = [
  Unit21DataClassifier.EVENT,
  Unit21DataClassifier.ACTION_EVENT,
  Unit21DataClassifier.ENTITY,
  Unit21DataClassifier.INSTRUMENT,
];

export const CLASSIFIER_TO_TYPE_NATIVE_KEY: Record<ClassifiableCGDOs, string> =
  {
    [Unit21DataClassifier.ACTION_EVENT]: 'action_type',
    [Unit21DataClassifier.EVENT]: 'type',
    [Unit21DataClassifier.ENTITY]: 'entity_type',
    [Unit21DataClassifier.INSTRUMENT]: 'instrument_type',
  };

export const DEFAULT_CLASSIFICATION: Record<
  ClassifiableCGDOs,
  Classifications
> = {
  [Unit21DataClassifier.ACTION_EVENT]: ActionEventTypeClassification.OTHER,
  [Unit21DataClassifier.EVENT]: TxnEventTypeClassification.OTHER,
  [Unit21DataClassifier.ENTITY]: EntityTypeClassification.OTHER,
  [Unit21DataClassifier.INSTRUMENT]: InstrumentTypeClassification.OTHER,
};

export const CLASSIFICATION_TO_READABLE_NAME = {
  [Unit21DataClassifier.ACTION_EVENT]: {
    [ActionEventTypeClassification.LOGIN]: 'Login',
    [ActionEventTypeClassification.LOGOUT]: 'Logout',
    [ActionEventTypeClassification.PERSONAL_INFO_UPDATE]:
      'Personal info update',
    [ActionEventTypeClassification.CARD_OR_ACCOUNT_UPDATE]:
      'Card/Account update',
    [ActionEventTypeClassification.PASSWORD_UPDATE]: 'Password update',
    [ActionEventTypeClassification.OTHER]: 'Other',
  },
  [Unit21DataClassifier.EVENT]: {
    [TxnEventTypeClassification.ACH]: 'ACH',
    [TxnEventTypeClassification.ASSET_TRADE]: 'Asset trade',
    [TxnEventTypeClassification.ATM]: 'ATM',
    [TxnEventTypeClassification.AUTHENTICATION]: 'Authentication',
    [TxnEventTypeClassification.CHARGEBACK]: 'Chargeback',
    [TxnEventTypeClassification.CHECK]: 'Check',
    [TxnEventTypeClassification.CRYPTO]: 'Crypto (crypto<->crypto)',
    [TxnEventTypeClassification.CRYPTO_FIAT_CONVERSION]:
      'Crypto (crypto<->fiat)',
    [TxnEventTypeClassification.DIRECT_DEPOSIT]: 'Direct deposit',
    [TxnEventTypeClassification.DISBURSMENT_INTEREST_DIVIDEND]:
      'Disbursment/Interest/Dividend',
    [TxnEventTypeClassification.DOMESTIC_WIRE]: 'Domestic wire',
    [TxnEventTypeClassification.FEE]: 'Fee',
    [TxnEventTypeClassification.INTERNATIONAL_WIRE]: 'International wire',
    [TxnEventTypeClassification.P2P]: 'Peer-to-peer (P2P)',
    [TxnEventTypeClassification.PAYMENT_DEBIT_CREDIT]: 'Payment/Debit/Credit',
    [TxnEventTypeClassification.PAYPAL]: 'PayPal',
    [TxnEventTypeClassification.PREAUTH]: 'Pre-Auth',
    [TxnEventTypeClassification.TRANSFER]: 'Transfer (not Wire/ACH)',

    [TxnEventTypeClassification.OTHER]: 'Other',
  } satisfies Record<TxnEventTypeClassification, string>,
  [Unit21DataClassifier.ENTITY]: {
    [EntityTypeClassification.PERSON_INDIVIDUAL_USER]: 'Person/Individual/User',
    [EntityTypeClassification.MERCHANT]: 'Merchant',
    [EntityTypeClassification.ORGANIZATION_BUSINESS]: 'Organization/Business',
    [EntityTypeClassification.SANCTIONED_ENTITY]: '314(a) entity',

    [EntityTypeClassification.OTHER]: 'Other',
  } satisfies Record<EntityTypeClassification, string>,
  [Unit21DataClassifier.INSTRUMENT]: {
    [InstrumentTypeClassification.BANK]: 'Bank',
    [InstrumentTypeClassification.BROKERAGE_RETIREMENT_ACCOUNT]:
      'Account (Brokerage/Retirement)',
    [InstrumentTypeClassification.CARD]: 'Card',
    [InstrumentTypeClassification.CRYPTO_WALLET_ADDRESS]:
      'Crypto Wallet/Address',
    [InstrumentTypeClassification.INTERNAL_ACCOUNT]: 'Account (Internal)',
    [InstrumentTypeClassification.LINKED_ACCOUNT]: 'Account (Linked/External)',
    [InstrumentTypeClassification.DEVICE]: 'Device',
    [InstrumentTypeClassification.OTHER]: 'Other',
  } satisfies Record<InstrumentTypeClassification, string>,
};

export const CLASSIFICATION_TO_DESCRIPTION = {
  [Unit21DataClassifier.ACTION_EVENT]: {
    [ActionEventTypeClassification.LOGIN]: '',
    [ActionEventTypeClassification.LOGOUT]: '',
    [ActionEventTypeClassification.PERSONAL_INFO_UPDATE]:
      'Any update to addresses/emails or other account information excluding password updates',
    [ActionEventTypeClassification.CARD_OR_ACCOUNT_UPDATE]:
      'Any update to accounts/cards i.e. cancellations/activations/resets',
    [ActionEventTypeClassification.PASSWORD_UPDATE]: '',
    [ActionEventTypeClassification.OTHER]: '',
  },
  [Unit21DataClassifier.EVENT]: {
    [TxnEventTypeClassification.ACH]:
      'Any NACHA Automated Clearing House (ACH) including any steps',
    [TxnEventTypeClassification.ASSET_TRADE]:
      'Any trade of any physical asset/stock/NFT',
    [TxnEventTypeClassification.ATM]: 'Any transaction occuring via an ATM',
    [TxnEventTypeClassification.AUTHENTICATION]:
      'Any transaction verifying account information. Exclude any Pre-Auth or pre-transaction holds',
    [TxnEventTypeClassification.CHARGEBACK]: 'Any card chargeback',
    [TxnEventTypeClassification.CHECK]:
      'Any transaction involving physical or eCheck',
    [TxnEventTypeClassification.CRYPTO]:
      'Any crypto transaction not involving a fiat currency',
    [TxnEventTypeClassification.CRYPTO_FIAT_CONVERSION]:
      'Any transaction converting between fiat and crypto currencies',
    [TxnEventTypeClassification.DIRECT_DEPOSIT]:
      'Electronic Funds Transfer (EFT)',
    [TxnEventTypeClassification.DISBURSMENT_INTEREST_DIVIDEND]:
      'Any disbursement/interest/dividend payment involving investments',
    [TxnEventTypeClassification.DOMESTIC_WIRE]: '',
    [TxnEventTypeClassification.FEE]:
      'Any fee originating from your platform or payment rails',
    [TxnEventTypeClassification.INTERNATIONAL_WIRE]: '',
    [TxnEventTypeClassification.P2P]: '',
    [TxnEventTypeClassification.PAYMENT_DEBIT_CREDIT]:
      'Any payment/card transaction that does not fall under the other categories (i.e. Pre-auths/chargebacks)',
    [TxnEventTypeClassification.PAYPAL]: '',
    [TxnEventTypeClassification.PREAUTH]:
      'Any Pre-Authorization/Holds for payment processing',
    [TxnEventTypeClassification.TRANSFER]: 'Transfers excluding ACHs/Wires',
    [TxnEventTypeClassification.OTHER]: '',
  } satisfies Record<TxnEventTypeClassification, string>,
  [Unit21DataClassifier.ENTITY]: {
    [EntityTypeClassification.PERSON_INDIVIDUAL_USER]: '',
    [EntityTypeClassification.ORGANIZATION_BUSINESS]: '',
    [EntityTypeClassification.MERCHANT]: '',
    [EntityTypeClassification.SANCTIONED_ENTITY]:
      'Entities being used to track 314(a) lists. These entities will be filtered out from the Data Explorer, Network Analysis, Customer Risk Ratings, and File Exports.',
    [EntityTypeClassification.OTHER]: '',
  } satisfies Record<EntityTypeClassification, string>,
  [Unit21DataClassifier.INSTRUMENT]: {
    [InstrumentTypeClassification.BANK]: 'Any bank or financial institude',
    [InstrumentTypeClassification.BROKERAGE_RETIREMENT_ACCOUNT]: '',
    [InstrumentTypeClassification.CARD]: '',
    [InstrumentTypeClassification.CRYPTO_WALLET_ADDRESS]:
      'Any crypto wallet or address',
    [InstrumentTypeClassification.INTERNAL_ACCOUNT]:
      'Any account owned/operated by your organization',
    [InstrumentTypeClassification.LINKED_ACCOUNT]:
      'Any bank account external to your organization used to transfer funds in and out (commonly called a `Linked Account`)',

    [InstrumentTypeClassification.DEVICE]:
      'Any digital fingerprint / unique identifier',

    [InstrumentTypeClassification.OTHER]: '',
  } satisfies Record<InstrumentTypeClassification, string>,
};

const mapVal =
  (classifier: ClassifiableCGDOs) =>
  (value: string): U21SelectOptionProps => ({
    value,
    text: CLASSIFICATION_TO_READABLE_NAME[classifier][value],
    description: CLASSIFICATION_TO_DESCRIPTION[classifier][value],
  });

export const CLASSIFICATION_OPTIONS: Record<
  ClassifiableCGDOs,
  U21SelectOptionProps[]
> = {
  [Unit21DataClassifier.ACTION_EVENT]: Object.values(
    ActionEventTypeClassification,
  ).map(mapVal(Unit21DataClassifier.ACTION_EVENT)),
  [Unit21DataClassifier.EVENT]: Object.values(TxnEventTypeClassification).map(
    mapVal(Unit21DataClassifier.EVENT),
  ),
  [Unit21DataClassifier.INSTRUMENT]: Object.values(
    InstrumentTypeClassification,
  ).map(mapVal(Unit21DataClassifier.INSTRUMENT)),
  [Unit21DataClassifier.ENTITY]: Object.values(EntityTypeClassification).map(
    mapVal(Unit21DataClassifier.ENTITY),
  ),
};

export const TXN_MONEY_MOVEMENT_OPTIONS: U21ButtonGroupButtonProps<string>[] =
  Object.values(TxnMovementType).map((value) => ({
    value,
    label: fromUpperToSentenceCase(value),
  }));
