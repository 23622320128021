import { useMemo } from 'react';

// components
import { Timeline } from 'react-event-timeline';
import {
  U21Alert,
  U21Button,
  U21Loading,
  U21NoData,
} from 'app/shared/u21-ui/components';

// queries
import { AuditTrailEntry } from 'app/modules/auditService/components/AuditTrailEntry';
import { useAuditServiceList } from 'app/modules/auditService/queries/useAuditServiceList';

// types
import {
  AuditTrailAssetType,
  AuditServiceEntry,
} from 'app/modules/auditService/types';

interface Props {
  assetType: AuditTrailAssetType;
  assetId: string;
}

/** A filter function to be use for an
 * array of `AuditServiceEntry`. This improves
 * the UI/UX of audit trails by preventing long
 * contiguous audits of the same action, such
 * as narrative edits (since they auto save).
 * If elements n and n+1 are both narrative edit
 * audits, remove n+1, for all n.
 */
const auditLogCompressor = (
  currentLog: AuditServiceEntry,
  index: number,
  array: AuditServiceEntry[],
): boolean => {
  /* Since we need a window size of 2 and
   * we look "back" to make the window, the first
   * element is _always_ carried through
   */
  if (index === 0) {
    return true;
  }

  /* If the current element and previous element are
   * one of the "filterable" audits, filter out the
   * current element
   */
  if (
    currentLog.action === 'alert.narrative_edit' &&
    array[index - 1].action === 'alert.narrative_edit'
  ) {
    return false;
  }
  if (
    currentLog.action === 'case.narrative_edit' &&
    array[index - 1].action === 'case.narrative_edit'
  ) {
    return false;
  }

  // Passing all if guards means it can stay in the array
  return true;
};

export const AuditTrail = ({ assetType, assetId }: Props) => {
  const { data, isLoading } = useAuditServiceList(assetType, assetId);

  /* This has to come _first_ before any rendering, the
   * early returning messes with react.
   */
  const compressedAuditLogs = useMemo(() => {
    if (data === undefined) {
      return [];
    }
    // This removes _some_ audit logs for readability.
    // Read the function for more details
    return data.auditLogs.filter(auditLogCompressor);
  }, [data]);

  if (isLoading) return <U21Loading loading />;
  if (!data || data.auditLogs.length === 0) return <U21NoData />;

  return (
    <>
      <Timeline>
        {compressedAuditLogs.map((entry) => (
          <AuditTrailEntry key={entry.createdAt} {...entry} />
        ))}
      </Timeline>
      {data.auditLogs.length >= 100 && (
        <U21Alert severity="info">
          Only the 100 most recent audit logs are available in the app. You can
          access older audit logs by using the Audit Service. Click{' '}
          <U21Button
            color="primary"
            href="https://docs.unit21.ai/docs/audit-requests"
            variant="text"
          >
            here
          </U21Button>{' '}
          for more information.
        </U21Alert>
      )}
    </>
  );
};
