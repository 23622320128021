// Redux
import { Reducer } from 'redux';

// Models
import {
  CustomChecklistActionTypes,
  CustomChecklistState,
} from 'app/shared/customChecklist/models';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Helpers
import { getValidatedCustomChecklist } from 'app/shared/customChecklist/helpers';

// Actions
import { CustomChecklistActions } from 'app/shared/customChecklist/actions';

const initialState: Readonly<CustomChecklistState> = {
  content: {},
  checklistId: 0,
  definition: undefined,
  name: '',
  validations: {},
  isValidated: false,
  history: [],
  modified_at: '',
  archived: false,
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<CustomChecklistState> = (
  state = initialState,
  action: CustomChecklistActions,
): CustomChecklistState => {
  if (
    action.type === CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUCCESS
  ) {
    const { id, definition, modified_at: modifiedAt, name } = action.payload;
    return {
      submissionId: 0,
      content: {},
      checklistId: id,
      definition,
      name,
      modified_at: modifiedAt,
      isValidated: false,
      validations: {},
      history: [],
      archived: false,
    };
  }

  if (
    action.type ===
    CustomChecklistActionTypes.RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION_SUCCESS
  ) {
    const {
      id,
      content,
      custom_checklist: customChecklist,
      history,
      modified_at: modifiedAt,
    } = action.payload;

    return getValidatedCustomChecklist({
      submissionId: id,
      content,
      checklistId: customChecklist.id,
      definition: customChecklist.definition,
      isValidated: false,
      validations: {},
      name: customChecklist.name,
      modified_at: modifiedAt,
      history,
      archived: customChecklist.archived,
    });
  }

  if (
    action.type === CustomChecklistActionTypes.SUBMIT_CUSTOM_CHECKLIST_SUCCESS
  ) {
    const { id, modified_at: modifiedAt, content } = action.payload;

    return getValidatedCustomChecklist({
      ...state,
      content,
      submissionId: id,
      modified_at: modifiedAt,
    });
  }

  if (action.type === CustomChecklistActionTypes.UPDATE_CUSTOM_CHECKLIST_ITEM) {
    const { key, value } = action.payload;
    return {
      ...state,
      content: {
        ...state.content,
        [key]: value,
      },
    };
  }

  if (action.type === CustomChecklistActionTypes.SET_CUSTOM_CHECKLIST_CONTENT) {
    return { ...state, content: action.payload };
  }

  if (
    action.type ===
    CustomChecklistActionTypes.SET_CUSTOM_CHECKLIST_SUBMISSION_ID
  ) {
    return { ...state, submissionId: action.payload };
  }

  return state;
};

export { reducer as customChecklistReducer, initialState };
