// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import { AdminState, AdminActionTypes } from 'app/modules/admin/models';

// Actions
import { AdminActions } from 'app/modules/admin/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<AdminState> = {
  orgs: [],
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<AdminState> = (
  state = initialState,
  action: AdminActions,
) => {
  return produce(state, (draft: Draft<AdminState>) => {
    switch (action.type) {
      case AdminActionTypes.RETRIEVE_ORGS_SUCCESS:
        draft.orgs = action.payload;
        return;
    }
  });
};

export { reducer as adminReducer, initialState };
