import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import {
  DataSettingFieldType,
  DataSettingsKeyType,
  FormattedData,
  FormattedDataCensorshipStatus,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import {
  getFormattedValue,
  keyPathToLabel,
} from 'app/modules/dataSettings/utils';
import {
  U21Chip,
  U21Typography,
  U21DataDisplay,
  U21DataDisplayAnyProps,
  U21DataDisplayDateProps,
  U21DataDisplayDatetimeProps,
  U21DataDisplayEnumProps,
  U21DataDisplayLinkProps,
  U21DataDisplayListProps,
  U21DataDisplayNumberProps,
  U21DataDisplayTextProps,
  U21DataDisplayVariant,
  U21NoValue,
  U21Spacer,
  U21Button,
} from 'app/shared/u21-ui/components';
import {
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { createSentryError } from 'app/shared/utils/sentry';
import { useSelector } from 'react-redux';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { PerspectiveChip } from 'app/modules/dataSettings/shared/PerspectiveChip';
import isURL from 'validator/lib/isURL';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import { selectDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { toSentenceCase } from 'app/shared/utils/string';
import { isRenderable } from 'app/shared/utils/react';

export const LIST_DISPLAY_FUNC = (value: string) => (
  <U21Chip key={value} variant="outlined">
    {value}
  </U21Chip>
);

const AMOUNT_KEYS = new Set([
  'amount',
  'received_amount',
  'sent_amount',
  'fee',
  'external_fee',
]);

interface Props {
  formattedData: FormattedData;
  dataSetting?: OrgDataSettingsConfig;
  negative?: boolean;
  dataDisplayProps?: {
    [U21DataDisplayVariant.ENUM]?: U21DataDisplayEnumProps['componentProps'];
    [U21DataDisplayVariant.NUMBER]?: U21DataDisplayNumberProps['componentProps'];
    [U21DataDisplayVariant.DATE]?: U21DataDisplayDateProps['componentProps'];
    [U21DataDisplayVariant.DATE_TIME]?: U21DataDisplayDatetimeProps['componentProps'];
    [U21DataDisplayVariant.TEXT]?: U21DataDisplayTextProps['componentProps'];
    [U21DataDisplayVariant.LIST]?: U21DataDisplayListProps<any>['componentProps'];
    [U21DataDisplayVariant.LINK]?: U21DataDisplayLinkProps['componentProps'];
    [U21DataDisplayVariant.ANY]?: U21DataDisplayAnyProps['componentProps'];
  };
}

export const DataSettingDataDisplay = ({
  dataDisplayProps,
  formattedData,
  dataSetting,
  negative = false,
}: Props) => {
  const dataSettingIdData = dataSetting
    ? formattedData[dataSetting.id]
    : undefined;

  const { is_parseable: isParsable, censorship } = dataSettingIdData ?? {};

  // if formatted data is empty or we can't find a dataSetting with the given ID
  const missingBaseData = isEmpty(formattedData) || !dataSetting;

  useEffect(() => {
    // if we have the base data but a data setting id is missing in formatted_data
    // we should raise a sentry error because
    // something is wrong in the backend we should know about
    if (!missingBaseData && !dataSettingIdData) {
      createSentryError({
        error: 'Data setting ID missing in `formatted_data`',
        info: `Data setting ID: ${dataSetting.id}.`,
      });
    }
  }, [missingBaseData, dataSettingIdData, dataSetting]);

  const value = dataSettingIdData
    ? getFormattedValue(dataSettingIdData)
    : undefined;

  // const [jsonPreview, setJsonPreview] = useState<boolean>(false);
  const formatAmount = useSelector(selectFormatAmount);
  const dataSettingsByKey = useSelector(selectDataSettingsByNativeKey);

  if (!dataSetting) {
    return (
      <U21DataDisplay
        variant="ANY"
        value={null}
        componentProps={dataDisplayProps?.ANY}
      />
    );
  }

  const dataSettingDataType = dataSetting.data_type;
  const valueIsNotRenderable =
    value === undefined || value === null || value === '';
  const baseCensorshipProps: Omit<CensorshipWrapperProps, 'children'> = {
    censorship,
    useChip:
      DATA_TYPES_WHICH_CAN_BE_WRAPPED_IN_CENSORSHIP_WRAPPER.has(
        dataSettingDataType,
      ) || valueIsNotRenderable,
    centerAlignRecensor:
      dataSettingDataType === DataSettingFieldType.ENUM ||
      dataSettingDataType === DataSettingFieldType.BOOLEAN,
  };

  const isAmount =
    dataSetting?.key_type === DataSettingsKeyType.NATIVE &&
    AMOUNT_KEYS.has(dataSetting.native_key);

  if (
    dataSettingDataType === DataSettingFieldType.ENTITY_REFERENCE ||
    dataSettingDataType === DataSettingFieldType.INSTRUMENT_REFERENCE
  ) {
    return (
      <CensorshipWrapper {...baseCensorshipProps}>
        <PerspectiveChip
          dataSetting={dataSetting}
          formattedDataItem={formattedData[dataSetting.id]}
        />
      </CensorshipWrapper>
    );
  }

  if (dataSettingDataType === DataSettingFieldType.JSON) {
    return (
      <CensorshipWrapper
        {...baseCensorshipProps}
        useChip={valueIsNotRenderable}
      >
        {valueIsNotRenderable ? (
          <U21NoValue />
        ) : (
          <U21DataDisplay
            title={dataSetting ? keyPathToLabel(dataSetting) : ''}
            subtitle={dataSetting?.description}
            variant="JSON"
            value={value}
          />
        )}
      </CensorshipWrapper>
    );
  }

  if (!isParsable) {
    return (
      <CensorshipWrapper {...baseCensorshipProps}>
        <U21DataDisplay
          componentProps={dataDisplayProps?.ANY}
          variant="ANY"
          value={value}
        />
      </CensorshipWrapper>
    );
  }

  // --- ENUMS ---
  if (dataSettingDataType === DataSettingFieldType.ENUM) {
    if (valueIsNotRenderable) {
      return (
        <CensorshipWrapper {...baseCensorshipProps}>
          <U21DataDisplay
            componentProps={dataDisplayProps?.ENUM}
            variant="ENUM"
            value={value}
          />
        </CensorshipWrapper>
      );
    }

    const color = dataSetting.rendering_options?.enum_mapping?.[value]?.color;
    const description =
      dataSetting.rendering_options?.enum_mapping?.[value]?.description;
    if (color) {
      return (
        <CensorshipWrapper
          {...baseCensorshipProps}
          // don't use a chip if we are rendering the enum in a chip, unless there is no value
          useChip={valueIsNotRenderable}
        >
          <U21DynamicColorChip color={color} tooltip={description}>
            {value}
          </U21DynamicColorChip>
        </CensorshipWrapper>
      );
    } else if (dataSetting.key_type === DataSettingsKeyType.NATIVE) {
      if (
        (dataSetting.unit21_data_classifier === Unit21DataClassifier.EVENT &&
          dataSetting.native_key === 'type') ||
        (dataSetting.unit21_data_classifier ===
          Unit21DataClassifier.ACTION_EVENT &&
          dataSetting.native_key === 'action_type')
      ) {
        return (
          <CensorshipWrapper
            {...baseCensorshipProps}
            // don't use a chip if we are rendering the enum in a chip, unless there is no value
            useChip={valueIsNotRenderable}
          >
            <U21DynamicColorChip tooltip={description}>
              {value}
            </U21DynamicColorChip>
          </CensorshipWrapper>
        );
      }
      if (
        dataSetting.unit21_data_classifier === Unit21DataClassifier.ENTITY &&
        dataSetting.native_key === 'entity_type'
      ) {
        return (
          <CensorshipWrapper
            {...baseCensorshipProps}
            // don't use a chip if we are rendering the enum in a chip, unless there is no value
            useChip={valueIsNotRenderable}
          >
            <U21Chip
              color={value === 'USER' ? 'warning' : 'info'}
              tooltip={description}
            >
              {value}
            </U21Chip>
          </CensorshipWrapper>
        );
      }
      if (
        dataSetting.unit21_data_classifier === Unit21DataClassifier.ENTITY &&
        dataSetting.native_key === 'status' &&
        ['active', 'inactive'].includes(value.toLowerCase())
      ) {
        return (
          <CensorshipWrapper
            {...baseCensorshipProps}
            // don't use a chip if we are rendering the enum in a chip, unless there is no value
            useChip={valueIsNotRenderable}
          >
            <U21Chip
              color={value === 'active' ? 'success' : 'error'}
              tooltip={description}
            >
              {toSentenceCase(value)}
            </U21Chip>
          </CensorshipWrapper>
        );
      }
    }
    return (
      <CensorshipWrapper {...baseCensorshipProps}>
        <U21DataDisplay
          componentProps={{
            tooltip: description,
            ellipsis: true,
            ...dataDisplayProps?.ENUM,
          }}
          variant="ENUM"
          value={value}
        />
      </CensorshipWrapper>
    );
  }

  // --- URLS ---
  if (
    (dataSettingDataType === DataSettingFieldType.NUMBER ||
      dataSettingDataType === DataSettingFieldType.TEXT) &&
    dataSetting?.rendering_options?.is_url
  ) {
    let hyperlink;
    if (dataSetting?.rendering_options?.is_url) {
      hyperlink = encodeURI(
        `${dataSetting?.rendering_options?.url_prefix || ''}${value}${
          dataSetting?.rendering_options?.url_postfix || ''
        }`,
      );
      const validatorOptions = {
        protocols: ['https'],
        require_protocol: true,
        require_valid_protocol: true,
        disallow_auth: true,
      };
      if (!isURL(hyperlink, validatorOptions)) {
        hyperlink = null;
      }
    }
    return (
      // if the hyperlink is invalid, then this is mere text so we can use the chip,
      // and if there is no value, we can also use the chip.
      <CensorshipWrapper
        {...baseCensorshipProps}
        // if there is a hyperlink and it's invalid it will be `null` and we dont want the chip
        useChip={valueIsNotRenderable || hyperlink === undefined}
        centerAlignRecensor
      >
        <U21DataDisplay
          componentProps={dataDisplayProps?.LINK}
          variant="LINK"
          value={value}
          hyperlink={hyperlink}
        />
      </CensorshipWrapper>
    );
  }

  // --- NUMBERS ---
  if (dataSettingDataType === DataSettingFieldType.NUMBER) {
    if (isAmount) {
      let currencyCodeProps = '';
      if (
        dataSetting.key_type === DataSettingsKeyType.NATIVE &&
        dataSetting.native_key.includes('sent')
      ) {
        const sentCurrencyDataSettingId =
          dataSettingsByKey[Unit21DataClassifier.EVENT]?.sent_currency?.id;
        currencyCodeProps =
          sentCurrencyDataSettingId &&
          formattedData[sentCurrencyDataSettingId]?.is_parseable
            ? formattedData[sentCurrencyDataSettingId].formatted_value
            : '';
      } else if (
        dataSetting.key_type === DataSettingsKeyType.NATIVE &&
        dataSetting.native_key.includes('received')
      ) {
        const sentCurrencyDataSettingId =
          dataSettingsByKey[Unit21DataClassifier.EVENT]?.received_currency?.id;
        currencyCodeProps =
          sentCurrencyDataSettingId &&
          formattedData[sentCurrencyDataSettingId]?.is_parseable
            ? formattedData[sentCurrencyDataSettingId].formatted_value
            : '';
      }
      return (
        <CensorshipWrapper {...baseCensorshipProps}>
          <U21DataDisplay
            // since we've added the currency symbol to the value, we need to treat it as a string
            variant="TEXT"
            value={
              negative
                ? `-${formatAmount({
                    amount: value,
                    currencyCodeProps,
                    precision: dataSetting?.rendering_options?.precision,
                  })}`
                : formatAmount({
                    amount: value,
                    currencyCodeProps,
                    precision: dataSetting?.rendering_options?.precision,
                  })
            }
            componentProps={{
              ...dataDisplayProps?.TEXT,
              ...(negative ? { color: 'error.main' } : {}),
            }}
          />
        </CensorshipWrapper>
      );
    }
    return (
      <CensorshipWrapper {...baseCensorshipProps}>
        <U21DataDisplay
          componentProps={{ ellipsis: true, ...dataDisplayProps?.NUMBER }}
          variant="NUMBER"
          value={value}
          {...(dataSetting?.rendering_options?.precision !== undefined &&
            dataSetting?.rendering_options?.precision !== null && {
              options: {
                minimumFractionDigits: 0,
                maximumFractionDigits: dataSetting.rendering_options.precision,
              },
            })}
        />
      </CensorshipWrapper>
    );
  }

  // --- DATETIMES ---
  if (dataSettingDataType === DataSettingFieldType.DATE_TIME) {
    return (
      <CensorshipWrapper {...baseCensorshipProps}>
        <U21DataDisplay
          componentProps={{ ellipsis: true, ...dataDisplayProps?.DATE_TIME }}
          variant="DATE_TIME"
          value={value}
          {...(dataSetting?.rendering_options?.timezone && {
            options: {
              timeZone: dataSetting.rendering_options.timezone,
            },
          })}
        />
      </CensorshipWrapper>
    );
  }

  // --- LISTS ---
  if (dataSettingDataType === DataSettingFieldType.LIST) {
    return (
      <CensorshipWrapper
        {...baseCensorshipProps}
        useChip={valueIsNotRenderable || !isRenderable(value)}
      >
        <U21DataDisplay
          variant="LIST"
          value={value}
          componentProps={{
            displayFunc:
              dataSetting.key_type === DataSettingsKeyType.NATIVE &&
              dataSetting.native_key === 'addresses'
                ? (val: string) => (
                    <U21Typography variant="body2" key={val}>
                      {val}
                    </U21Typography>
                  )
                : LIST_DISPLAY_FUNC,
            ...dataDisplayProps?.LIST,
          }}
        />
      </CensorshipWrapper>
    );
  }

  // --- REST ---
  return (
    <CensorshipWrapper {...baseCensorshipProps}>
      <U21DataDisplay
        componentProps={{
          ellipsis: true,
          ...dataDisplayProps?.[dataSettingDataType],
        }}
        variant={dataSettingDataType}
        value={value}
      />
    </CensorshipWrapper>
  );
};

const DATA_TYPES_WHICH_CAN_BE_WRAPPED_IN_CENSORSHIP_WRAPPER: Set<DataSettingFieldType> =
  new Set([
    // with the exception of enums with chips
    DataSettingFieldType.ENUM,
    DataSettingFieldType.NUMBER,
    DataSettingFieldType.DATE_TIME,
    DataSettingFieldType.DATE,
    DataSettingFieldType.TEXT,
  ]);

interface CensorshipWrapperProps {
  censorship: FormattedDataCensorshipStatus | undefined;
  useChip: boolean;
  centerAlignRecensor?: boolean;
  children: ReactNode;
}

const CensorshipWrapper = forwardRef<HTMLDivElement, CensorshipWrapperProps>(
  ({ censorship, useChip, centerAlignRecensor = false, children }, ref) => {
    const [uncensored, setUncensored] = useState(false);
    const isCensored = useMemo(() => {
      return (
        censorship && censorship !== FormattedDataCensorshipStatus.UNCENSORED
      );
    }, [censorship]);

    if (!isCensored) {
      return children;
    }

    let icon: ReactElement | undefined;
    if (censorship === FormattedDataCensorshipStatus.UNCENSORABLE) {
      icon = uncensored ? <IconEyeOff /> : <IconEye />;
    }
    const censorshipChip = (
      <U21Chip
        icon={icon}
        onClick={
          censorship === FormattedDataCensorshipStatus.UNCENSORABLE
            ? (e) => {
                e.stopPropagation();
                setUncensored((prev) => !prev);
              }
            : undefined
        }
        ref={ref}
      >
        {uncensored ? children : '••••••'}
      </U21Chip>
    );

    if (useChip) {
      return censorshipChip;
    }
    return (
      <U21Spacer horizontal align={centerAlignRecensor ? 'center' : 'start'}>
        {uncensored ? (
          <U21Button
            icon={icon}
            size="small"
            onClick={
              censorship === FormattedDataCensorshipStatus.UNCENSORABLE
                ? (e) => {
                    e.stopPropagation();
                    setUncensored(false);
                  }
                : undefined
            }
            tooltip="Censor"
            aria-label="Censor"
          />
        ) : (
          censorshipChip
        )}
        {uncensored ? children : null}
      </U21Spacer>
    );
  },
);
