import pluralize from 'pluralize';
import {
  U21Button,
  U21ButtonGroup,
  U21Chip,
  U21FormLabel,
  U21Section,
  U21Spacer,
  U21TextField,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectLinkAnalysisFilters } from 'app/modules/networkAnalysis/selectors';
import {
  EMPTY_FILTERS,
  LINK_ANALYSIS_LINK_TYPES,
} from 'app/modules/networkAnalysis/constants';
import {
  setFilters,
  clearFilters,
} from 'app/modules/networkAnalysis/sliceNetworkAnalysis';
import { useEntityLinks } from 'app/modules/networkAnalysis/queries/useEntityLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { NetworkAnalysisResponse } from 'app/modules/networkAnalysis/types';
import { useCallback, useEffect, useMemo } from 'react';
import { getFilterOptions } from 'app/modules/networkAnalysis/helpers';
import { IconX } from '@u21/tabler-icons';
import { isEmpty, isEqual } from 'lodash';

const getLinkTypeStats = (
  data: NetworkAnalysisResponse,
): Record<string, number> => {
  return Object.values(data.links).reduce(
    (acc, link) => {
      const { type } = link;
      if (acc[type]) {
        acc[type] += 1;
      } else {
        acc[type] = 1;
      }
      return acc;
    },
    {
      [LINK_ANALYSIS_LINK_TYPES.TRANSACTION]: (
        Object.keys(data.transactions) ?? []
      ).length,
    },
  );
};

const FilterSection = ({
  title,
  type,
  options,
  stats,
}: {
  title: string;
  type: keyof typeof EMPTY_FILTERS;
  options: Array<string>;
  stats?: Record<string, number>;
}) => {
  const filters = useSelector(selectLinkAnalysisFilters);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  if (type === 'transactionData') {
    return null;
  }
  const filter = new Set(filters[type]);
  return (
    <U21Spacer>
      <U21Typography>{title}:</U21Typography>
      <U21Spacer horizontal wrap>
        {options.map((v) => (
          <U21Chip
            icon={filter.has(v) ? <IconX /> : undefined}
            variant={filter.has(v) ? 'ghost' : 'outlined'}
            color={filter.has(v) ? 'primary' : undefined}
            onClick={() => {
              if (filter.has(v)) {
                filter.delete(v);
              } else {
                filter.add(v);
              }
              dispatch(setFilters({ type, val: Array.from(filter) }));
              history.replace(pathname);
            }}
            rounded
            key={v}
          >
            {stats ? pluralize(v, stats[v], true) : v}
          </U21Chip>
        ))}
      </U21Spacer>
    </U21Spacer>
  );
};

interface FiltersProps {
  entityId: string;
  entityExternalId: string;
}

export const TRANSACTION_SENDING_RECEIVING_OPTIONS = [
  { label: 'Both', value: 'BOTH' as const },
  { label: 'Sending', value: 'SENDING' as const },
  { label: 'Receiving', value: 'RECEIVING' as const },
];

export const Filters = ({ entityId, entityExternalId }: FiltersProps) => {
  const { data } = useEntityLinks(entityId, entityExternalId);
  const filters = useSelector(selectLinkAnalysisFilters);
  const dispatch = useDispatch();
  const filterOptions = useMemo(() => getFilterOptions(data), [data]);

  const clearAllFilters = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  // clear filters on dismount
  useEffect(
    () => () => {
      clearAllFilters();
    },
    [clearAllFilters],
  );

  if (!data) {
    return null;
  }

  return (
    <U21Section
      title={
        <U21Spacer horizontal align="center">
          <span>Network Analysis</span>
          <U21Chip>
            {pluralize('Link', Object.keys(data.links).length, true)}
          </U21Chip>
          <U21Chip>
            {pluralize(
              'Transaction',
              Object.keys(data.transactions).length,
              true,
            )}
          </U21Chip>
          <U21Chip>
            {pluralize('Entity', Object.keys(data.entities).length, true)}
          </U21Chip>
        </U21Spacer>
      }
      action={
        <U21Button
          size="small"
          startIcon={<IconX />}
          onClick={clearAllFilters}
          variant="contained"
          disabled={isEqual(filters, EMPTY_FILTERS)}
        >
          Clear all
        </U21Button>
      }
    >
      <U21Spacer spacing={5}>
        <U21Spacer spacing={2}>
          <U21Typography variant="subtitle1">Filter by</U21Typography>
          {!isEmpty(filterOptions.linkType) && (
            <FilterSection
              title="Link"
              type="linkType"
              options={filterOptions.linkType}
              stats={getLinkTypeStats(data)}
            />
          )}
          {!isEmpty(data.transactions) && (
            <U21Spacer>
              <U21Typography>Transaction data:</U21Typography>
              <U21Spacer horizontal wrap>
                <U21Spacer>
                  <U21FormLabel>Transaction direction:</U21FormLabel>
                  <U21ButtonGroup
                    value={filters.transactionData.direction}
                    buttons={TRANSACTION_SENDING_RECEIVING_OPTIONS}
                    onClick={(value) => {
                      dispatch(
                        setFilters({
                          type: 'transactionData',
                          val: {
                            ...filters.transactionData,
                            direction: value,
                          },
                        }),
                      );
                    }}
                  />
                </U21Spacer>
                <U21Spacer>
                  <U21FormLabel>Transaction minimum:</U21FormLabel>
                  <U21TextField
                    value={filters.transactionData?.min}
                    placeholder="Enter an amount..."
                    onChange={(min: number) => {
                      dispatch(
                        setFilters({
                          type: 'transactionData',
                          val: { ...filters.transactionData, min: min ?? 0 },
                        }),
                      );
                    }}
                    type="number"
                  />
                </U21Spacer>
              </U21Spacer>
            </U21Spacer>
          )}
        </U21Spacer>
        {(!isEmpty(filterOptions.entityType) ||
          !isEmpty(filterOptions.entitySubtype) ||
          !isEmpty(filterOptions.entityStatus)) && (
          <U21Spacer spacing={2}>
            <U21Typography variant="subtitle1">Show group</U21Typography>
            {!isEmpty(filterOptions.entityType) && (
              <FilterSection
                title="Entity type"
                type="entityType"
                options={filterOptions.entityType}
              />
            )}
            {!isEmpty(filterOptions.entitySubtype) && (
              <FilterSection
                title="Entity subtype"
                type="entitySubtype"
                options={filterOptions.entitySubtype}
              />
            )}
            {!isEmpty(filterOptions.entityStatus) && (
              <FilterSection
                title="Entity status"
                type="entityStatus"
                options={filterOptions.entityStatus}
              />
            )}
          </U21Spacer>
        )}
      </U21Spacer>
    </U21Section>
  );
};
