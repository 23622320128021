// Constants
import { initialState } from 'app/modules/admin/reducer';

// Models
import { createSelector } from 'reselect';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const selectOrgs = (state: RootState) => {
  return state.admin.orgs || initialState.orgs;
};

export const selectOrgsOptions = createSelector([selectOrgs], (orgs) =>
  orgs.map<U21SelectOptionProps>((i) => {
    return {
      value: i.id,
      text: i.display_name,
    };
  }),
);
